import {
  BellOutlined,
  HomeOutlined,
  PlusOutlined,
  SettingOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { Badge, Button, Carousel, Flex, Modal, Typography } from "antd";
import { CarouselRef } from "antd/es/carousel";
import dayjs from "dayjs";
import React, { MouseEvent, useEffect, useRef, useState } from "react";
import { isIOS } from "react-device-detect";
import Lottie, { Options } from "react-lottie";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import doneAnimationData from "../../core/assets/lottie/confetti-done.json";
import stepAnimationData from "../../core/assets/lottie/confetti-step.json";
import Empty from "../../core/components/empty/empty";
import HabitGoalCard from "../../core/components/habit-goal-card/habit-goal-card";
import {
  ContentStyled as ContentBaseStyled,
  LayoutHome,
} from "../../core/components/layout/layout";
import { animationOptions } from "../../core/consts/animation-options";
import { getUnitsFormatted } from "../../core/formatters";
import getFormattedString from "../../core/utils/date-format";
import mixpanelService from "../../services/mixpanel-service";
import useHydrationNotifications from "../../services/use-hydration-notifications";
import { useInstalledStatus } from "../../services/use-installed-status";
import useHabitStore from "../../store/habit-store";
import useNotificationStore from "../../store/notification-store";
import useSettingsStore from "../../store/settings-store";
import { Habit } from "../../types/habit";
import Calendar from "./calendar";
import { HomeSegmented } from "./home-segmented";

const { Title, Paragraph } = Typography;

const doneAnimationOptions: Options = {
  ...animationOptions,
  loop: false,
  animationData: doneAnimationData,
};

const stepAnimationOptions: Options = {
  ...animationOptions,
  loop: false,
  animationData: stepAnimationData,
};

type HabitTypesView = "all" | "habits" | "tasks";

const headerHeight = "calc(184px + var(--safe-area-top))";
const footerHeight = "calc(88px + var(--safe-area-bottom))";

const CarouselItemContainer = styled.div<{
  $active?: boolean;
}>`
  padding: 0 16px;
  max-height: ${(props) =>
    props.$active
      ? "auto"
      : `calc(100vh - ${headerHeight}px - ${footerHeight}px)`};
`;

const successSound = document.querySelector(
  "#success-sound"
) as HTMLAudioElement;
const levelUpSound = document.querySelector(
  "#level-up-sound"
) as HTMLAudioElement;

successSound.volume = 0.1;
levelUpSound.volume = 0.1;

const HeaderBackdrop = styled.div`
  position: fixed;
  top: 0;
  height: ${headerHeight};
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  z-index: 1;
  width: 100%;
  max-width: var(--max-width);
`;

const ContentStyled = styled(ContentBaseStyled)`
  padding: ${headerHeight} 0 ${footerHeight};
  overflow: auto;
`;

const Home: React.FC = () => {
  const carouselRef = useRef<CarouselRef>(null);
  const installed = useInstalledStatus();
  const [preSelectedItemType, setPreSelectedItemType] =
    useState<HabitTypesView>("habits");
  const [selectedItemType, setSelectedItemType] =
    useState<HabitTypesView>("habits");

  const [hasDoneAnimation, setHasDoneAnimation] = useState(false);
  const [showDoneAnimation, setShowDoneAnimation] = useState(false);

  const [hasStepAnimation, setHasStepAnimation] = useState(false);
  const [showStepAnimation, setShowStepAnimation] = useState(false);
  const [stepAnimationPosition, setStepAnimationPosition] = useState({
    top: 0,
    left: 0,
  });

  const navigate = useNavigate();
  useHydrationNotifications();

  const { increaseHistory, setDay, getFilteredItems, markAsDone } =
    useHabitStore.getState();

  const { setInstallAppModalOpened } = useSettingsStore.getState();

  const installAppModalOpened = useSettingsStore(
    (state) => state.installAppModalOpened
  );
  const currentDay = useHabitStore((state) => state.currentDay);
  const fullHistory = useHabitStore((state) => state.history);
  const sounds = useSettingsStore((state) => state.sounds);
  const notifications = useNotificationStore((state) => state.notifications);
  const allItems = useHabitStore((store) => store.allItems);

  const calcStep = (habit: Habit): number => {
    const {
      unit: { count: total, type },
    } = habit.goal ?? { unit: { count: 0 } };
    const history = fullHistory[habit.id] ?? [];

    const selectedDayHistory = history.find((h) =>
      h.date.isSame(currentDay, "day")
    );

    if (type === "timer") {
      return Math.max(Math.floor(total / 2), 1);
    }

    if (selectedDayHistory) {
      const { count } = selectedDayHistory;

      if (count === 0) {
        return Math.floor(total / 4);
      }

      return Math.max(Math.floor((total - count) / 4), 1);
    }

    return Math.max(Math.floor(total / 4), 1);
  };

  const calcStepFormatted = (habit: Habit): string => {
    const {
      unit: { type },
    } = habit.goal ?? { unit: { type: "times" } };

    const step = calcStep(habit);

    return `+${getUnitsFormatted(step, type)}`;
  };

  const getCurrentProgress = (habitId: string) => {
    const history = fullHistory[habitId] ?? [];

    const selectedDayHistory = history.find((h) =>
      h.date.isSame(currentDay, "day")
    );

    return selectedDayHistory?.count ?? 0;
  };

  const getType = (habit: Habit): string => {
    return habit.goal?.unit.count !== 1 ? habit.goal?.unit.type ?? "" : "";
  };

  const getTotalProgress = (habit: Habit): number => {
    return habit.goal?.unit.count ?? 0;
  };

  const isDoneForCurrentDay = (habitId: string): boolean => {
    const history = fullHistory[habitId] ?? [];

    const selectedDayHistory = history.find((h) =>
      h.date.isSame(currentDay, "day")
    );

    return selectedDayHistory?.done ?? false;
  };

  const getSubtitle = (item: Habit): string => {
    const percent = Math.floor(
      (getCurrentProgress(item.id) / getTotalProgress(item)) * 100
    );

    if (currentDay.isToday()) {
      let message = "";

      if (percent === 0) {
        message = "You haven't started yet.";
      }

      if (percent > 0 && percent < 100) {
        message = "You're doing great!";
      }

      if (percent === 100) {
        message = "Done!";
      }

      return !isDoneForCurrentDay(item.id)
        ? item.description || message
        : message;
    }

    if (isDoneForCurrentDay(item.id)) {
      return "Done!";
    }

    return item.description || "";
  };

  const unreadCount = notifications.filter((item) => !item.isRead)?.length;
  const currentDayItems = getFilteredItems();
  const currentDayHabits = currentDayItems.filter((item) => !!item.repeat);
  const currentDayTasks = currentDayItems.filter((item) => !item.repeat);

  useEffect(() => {
    if (showDoneAnimation) {
      setTimeout(() => {
        setShowDoneAnimation(false);

        setTimeout(() => {
          setHasDoneAnimation(false);
        }, 400);
      }, 1300);
    }
  }, [showDoneAnimation]);

  useEffect(() => {
    if (showStepAnimation) {
      setTimeout(() => {
        setShowStepAnimation(false);

        setTimeout(() => {
          setHasStepAnimation(false);
        }, 400);
      }, 1300);
    }
  }, [showStepAnimation]);

  const getOffset = (el: HTMLElement): { top: number; left: number } => {
    const rect = el.getBoundingClientRect();
    return {
      left: rect.left + window.scrollX,
      top: rect.top + window.scrollY,
    };
  };

  const makeStepClickHandler = (
    step: number,
    event: MouseEvent,
    id: string
  ): void => {
    setStepAnimationPosition(getOffset(event.target as HTMLElement));
    increaseHistory(id, step);
    sounds && successSound.play();
    setHasStepAnimation(true);
    setShowStepAnimation(true);
  };

  const markAsDoneClickHandler = (id: string): void => {
    markAsDone(id);
    sounds && levelUpSound.play();
    setHasDoneAnimation(true);
    setShowDoneAnimation(true);
  };

  const tasksOptions: { label: string; value: HabitTypesView }[] = [
    {
      label: "All",
      value: "all",
    },
    {
      label: "Habits",
      value: "habits",
    },
    {
      label: "Tasks",
      value: "tasks",
    },
  ];

  const onItemTypeChange = (next: number) => {
    setSelectedItemType(tasksOptions[next].value);
  };

  const beforeOnItemTypeChange = (_: number, next: number) => {
    setPreSelectedItemType(tasksOptions[next].value);
  };

  return (
    <>
      <HeaderBackdrop />
      <LayoutHome
        header={
          <>
            <Button
              icon={<UnorderedListOutlined />}
              onClick={() => {
                mixpanelService.trackClickViewList();
                navigate("/items");
              }}
              type="text"
              shape="circle"
              size="large"
            />

            <Title
              level={3}
              style={{ flex: 1, margin: 0, textAlign: "center" }}
            >
              {getFormattedString(currentDay)}
            </Title>

            <Badge count={unreadCount} overflowCount={9} size="small">
              <Button
                type="text"
                shape="circle"
                size="large"
                icon={<BellOutlined />}
                onClick={() => {
                  mixpanelService.trackClickNotification();
                  navigate("/notifications");
                }}
              />
            </Badge>
          </>
        }
        content={
          <>
            <Calendar
              currentSelection={currentDay}
              onSmash={(day) => {
                mixpanelService.trackClickCalendar();
                setDay(day);
              }}
            />

            <HomeSegmented
              options={tasksOptions}
              size="large"
              block
              disabled={!allItems.length}
              value={selectedItemType}
              onChange={(value) => {
                setPreSelectedItemType(value as HabitTypesView);
                setSelectedItemType(value as HabitTypesView);
                carouselRef.current?.goTo(
                  tasksOptions.findIndex((option) => option.value === value)
                );

                if (value === "all") {
                  mixpanelService.trackClickShowAll();
                }

                if (value === "habits") {
                  mixpanelService.trackClickShowHabits();
                }

                if (value === "tasks") {
                  mixpanelService.trackClickShowTasks();
                }
              }}
            />
            <ContentStyled>
              {allItems.length ? (
                <Carousel
                  ref={carouselRef}
                  dots={false}
                  beforeChange={beforeOnItemTypeChange}
                  afterChange={onItemTypeChange}
                  initialSlide={tasksOptions.findIndex(
                    (option) => option.value === selectedItemType
                  )}
                >
                  <CarouselItemContainer $active={selectedItemType === "all"}>
                    <Flex gap="middle" vertical flex={1}>
                      {currentDayItems.length ? (
                        currentDayItems.map((item) => (
                          <HabitGoalCard
                            key={item.id}
                            icon={item.icon}
                            title={item.title}
                            subtitle={getSubtitle(item)}
                            currentProgress={getCurrentProgress(item.id)}
                            totalProgress={getTotalProgress(item)}
                            type={getType(item)}
                            step={calcStep(item)}
                            stepFormatted={calcStepFormatted(item)}
                            navigate={() => navigate(`/items/${item.id}`)}
                            makeStep={(step, event) => {
                              makeStepClickHandler(step, event, item.id);
                              mixpanelService.trackMakeStep();
                            }}
                            markAsDone={() => {
                              markAsDoneClickHandler(item.id);
                              mixpanelService.trackMarkAsDone();
                            }}
                            done={isDoneForCurrentDay(item.id)}
                            disabled={
                              (!currentDay.isToday() &&
                                currentDay.isAfter(dayjs(), "day")) ||
                              currentDay.isBefore(
                                dayjs().add(-1, "day"),
                                "day"
                              ) ||
                              isDoneForCurrentDay(item.id)
                            }
                          />
                        ))
                      ) : (
                        <Empty
                          text={
                            allItems.length ? "Day off" : "Add new habit ⤵️"
                          }
                          showAnimation={preSelectedItemType === "all"}
                        />
                      )}
                    </Flex>
                  </CarouselItemContainer>

                  <CarouselItemContainer
                    $active={selectedItemType === "habits"}
                  >
                    <Flex gap="middle" vertical flex={1}>
                      {currentDayHabits.length ? (
                        currentDayHabits.map((item) => (
                          <HabitGoalCard
                            key={item.id}
                            icon={item.icon}
                            title={item.title}
                            subtitle={getSubtitle(item)}
                            currentProgress={getCurrentProgress(item.id)}
                            totalProgress={getTotalProgress(item)}
                            type={getType(item)}
                            step={calcStep(item)}
                            stepFormatted={calcStepFormatted(item)}
                            navigate={() => navigate(`/items/${item.id}`)}
                            makeStep={(step, event) =>
                              makeStepClickHandler(step, event, item.id)
                            }
                            markAsDone={() => markAsDoneClickHandler(item.id)}
                            done={isDoneForCurrentDay(item.id)}
                            disabled={
                              (!currentDay.isToday() &&
                                currentDay.isAfter(dayjs(), "day")) ||
                              currentDay.isBefore(
                                dayjs().add(-1, "day"),
                                "day"
                              ) ||
                              isDoneForCurrentDay(item.id)
                            }
                          />
                        ))
                      ) : (
                        <Empty
                          text={
                            allItems.length ? "Day off" : "Add new habit ⤵️"
                          }
                          showAnimation={preSelectedItemType === "habits"}
                        />
                      )}
                    </Flex>
                  </CarouselItemContainer>

                  <CarouselItemContainer $active={selectedItemType === "tasks"}>
                    <Flex gap="middle" vertical flex={1}>
                      {currentDayTasks.length ? (
                        currentDayTasks.map((item) => (
                          <HabitGoalCard
                            key={item.id}
                            icon={item.icon}
                            title={item.title}
                            subtitle={getSubtitle(item)}
                            currentProgress={getCurrentProgress(item.id)}
                            totalProgress={getTotalProgress(item)}
                            type={getType(item)}
                            step={calcStep(item)}
                            stepFormatted={calcStepFormatted(item)}
                            navigate={() => navigate(`/items/${item.id}`)}
                            makeStep={(step, event) =>
                              makeStepClickHandler(step, event, item.id)
                            }
                            markAsDone={() => markAsDoneClickHandler(item.id)}
                            done={isDoneForCurrentDay(item.id)}
                            disabled={
                              (!currentDay.isToday() &&
                                currentDay.isAfter(dayjs(), "day")) ||
                              currentDay.isBefore(
                                dayjs().add(-1, "day"),
                                "day"
                              ) ||
                              isDoneForCurrentDay(item.id)
                            }
                          />
                        ))
                      ) : (
                        <Empty
                          text={
                            !allItems.length ? "Day off" : "Add new task ⤵️"
                          }
                          showAnimation={preSelectedItemType === "tasks"}
                        />
                      )}
                    </Flex>
                  </CarouselItemContainer>
                </Carousel>
              ) : (
                <Empty text="Add new habit ⤵️" showAnimation={true} />
              )}
            </ContentStyled>
          </>
        }
        footer={
          <>
            <Button
              type="text"
              icon={<HomeOutlined />}
              size="large"
              onClick={() => {
                setDay(dayjs());
                mixpanelService.trackClickHomeButton();
              }}
            />

            <Link
              to="new"
              onClick={() => {
                mixpanelService.trackClickAddNew();
              }}
            >
              <Button type="primary" size="large" icon={<PlusOutlined />} />
            </Link>

            <Link
              to="settings"
              onClick={() => {
                mixpanelService.trackClickSettings();
              }}
            >
              <Button type="text" size="large" icon={<SettingOutlined />} />
            </Link>
          </>
        }
      />

      {hasDoneAnimation && (
        <div
          style={{
            position: "absolute",
            bottom: 0,
            pointerEvents: "none",
            visibility: showDoneAnimation ? "visible" : "hidden",
            opacity: showDoneAnimation ? 1 : 0,
            transition: "all 0.3s linear",
            zIndex: 1,
          }}
        >
          <Lottie
            options={doneAnimationOptions}
            isClickToPauseDisabled={true}
          />
        </div>
      )}

      {hasStepAnimation && (
        <div
          style={{
            position: "absolute",
            top: stepAnimationPosition.top - 150,
            left: stepAnimationPosition.left - 150,
            pointerEvents: "none",
            // visibility: showStepAnimation ? "visible" : "hidden",
            // opacity: showStepAnimation ? 1 : 0,
            transition: "all 0.3s linear",
          }}
        >
          <Lottie
            options={stepAnimationOptions}
            width={300}
            height={300}
            isClickToPauseDisabled={true}
          />
        </div>
      )}

      {!installed && (
        <Modal
          centered
          title={`Welcome to ${process.env.REACT_APP_NAME}`}
          open={installAppModalOpened}
          footer={null}
          closable={false}
        >
          <Flex align="center" vertical>
            {isIOS ? (
              <>
                <Paragraph>
                  For better experience, install the app on your device. We
                  support offline mode and push notifications.
                </Paragraph>

                <Paragraph>
                  <figure>
                    <figcaption>
                      <strong>Steps to install:</strong>
                    </figcaption>
                    <ul>
                      <li>
                        Open the Share menu, available at the bottom or top of
                        the browser.
                      </li>
                      <li>Click Add to Home Screen</li>
                      <li>
                        Confirm the name of the app; the name is user-editable.
                        Click Add.
                      </li>

                      <li>
                        On iOS and iPadOS, bookmarks to websites and PWAs look
                        the same on the home screen.
                      </li>
                    </ul>
                  </figure>
                </Paragraph>
              </>
            ) : (
              <>
                <Paragraph>
                  For a better experience, install the app on your Android
                  device. We support offline mode and push notifications.
                </Paragraph>

                <Paragraph>
                  <figure>
                    <figcaption>
                      <strong>Steps to install:</strong>
                    </figcaption>
                    <ul>
                      <li>
                        Open the browser menu, usually located in the top-right
                        or bottom-right corner.
                      </li>
                      <li>Choose "Add to Home Screen" or a similar option.</li>
                      <li>
                        Confirm the name of the app; the name is user-editable.
                        Tap "Add" or "Install."
                      </li>
                      <li>
                        On Android, shortcuts to websites and Progressive Web
                        Apps (PWAs) will appear on the home screen.
                      </li>
                    </ul>
                  </figure>
                </Paragraph>
              </>
            )}

            <Button
              type="primary"
              onClick={() => {
                setInstallAppModalOpened(false);
                mixpanelService.trackGotInstallInfo();
              }}
              style={{ marginTop: 16 }}
            >
              Got it
            </Button>
          </Flex>
        </Modal>
      )}
    </>
  );
};

export default Home;
