import React, { MouseEvent } from "react";
import { Button, Card, Flex, Progress, Typography } from "antd";
import { CheckOutlined } from "@ant-design/icons";

const { Text, Paragraph } = Typography;

interface HabitCardProps {
  icon: string;
  title: string;
  subtitle: string;
  type: string;
  navigate: () => void;
  currentProgress: number;
  totalProgress: number;
  step: number;
  stepFormatted: string;
  makeStep: (step: number, event: MouseEvent) => void;
  markAsDone: () => void;
  disabled: boolean;
  done: boolean;
}

const HabitGoalCard: React.FC<HabitCardProps> = ({
  icon,
  title,
  subtitle,
  currentProgress,
  totalProgress,
  type,
  step,
  stepFormatted,
  navigate,
  makeStep,
  disabled,
  markAsDone,
  done,
}) => {
  const twoColors = { "0%": "#108ee9", "100%": "#87d068" };
  const percent = Math.floor((currentProgress / totalProgress) * 100);

  const actions = [
    <Button
      size="small"
      icon={
        <CheckOutlined
          key="check"
          style={!disabled ? { color: "#87d068" } : {}}
        />
      }
      disabled={disabled}
      onClick={(event) => {
        event.stopPropagation();
        markAsDone();
      }}
    />,
  ];

  if (type) {
    actions.unshift(
      <>
        <Button
          size="small"
          onClick={(event) => {
            event.stopPropagation();
            makeStep(step, event);
          }}
          disabled={disabled || percent === 100}
        >
          {stepFormatted}
        </Button>
      </>
    );
  }

  return (
    <Card
      style={{ width: "100%" }}
      actions={actions}
      onClick={navigate}
      bordered={false}
      size="small"
      title={<Text delete={done}>{title}</Text>}
    >
      <Flex style={{ width: "100%" }} gap="small" align="center">
        {React.cloneElement(<span>{icon}</span>, {
          style: {
            fontSize: "46px",
            lineHeight: "46px",
            alignItems: "center",
            minWidth: "46px",
            overflow: "hidden",
          },
        })}

        <Flex gap="small" vertical flex={1}>
          <Paragraph
            type="secondary"
            ellipsis={{
              rows: 2,
            }}
            style={{ margin: 0 }}
          >
            {subtitle}
          </Paragraph>

          {type && (
            <Progress
              percent={percent}
              showInfo={false}
              strokeColor={twoColors}
            />
          )}
        </Flex>
      </Flex>
    </Card>
  );
};

export default HabitGoalCard;
