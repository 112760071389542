import mixpanel, { OverridedMixpanel } from "mixpanel-browser";

class MixpanelService {
  private mixpanel: OverridedMixpanel | null = null;

  inject(): void {
    if (!process.env.REACT_APP_MIXPANEL) {
      return;
    }

    this.mixpanel = mixpanel;

    this.mixpanel.init(process.env.REACT_APP_MIXPANEL, {
      debug: true,
      ignore_dnt: true,
    });

    this.mixpanel.identify();
  }

  registerInstallation(hasInstallation: boolean): void {
    if (process.env.NODE_ENV === "production") {
      this.mixpanel?.register({
        "App Installed": hasInstallation,
      });
    }
  }

  trackAppInstalled(): void {
    if (process.env.NODE_ENV === "production") {
      this.mixpanel?.track("App Installed");
    }
  }

  trackClickHomeButton(): void {
    if (process.env.NODE_ENV === "production") {
      this.mixpanel?.track("Click Home Button");
    }
  }

  trackClickAddNew(): void {
    if (process.env.NODE_ENV === "production") {
      this.mixpanel?.track("Click Add New");
    }
  }

  trackClickSettings(): void {
    if (process.env.NODE_ENV === "production") {
      this.mixpanel?.track("Click Settings");
    }
  }

  trackClickCalendar(): void {
    if (process.env.NODE_ENV === "production") {
      this.mixpanel?.track("Click Calendar");
    }
  }

  trackClickNotification(): void {
    if (process.env.NODE_ENV === "production") {
      this.mixpanel?.track("Click Notification");
    }
  }

  trackClickViewList(): void {
    if (process.env.NODE_ENV === "production") {
      this.mixpanel?.track("Click View List");
    }
  }

  trackClickShowAll(): void {
    if (process.env.NODE_ENV === "production") {
      this.mixpanel?.track("Click Show All");
    }
  }

  trackClickShowHabits(): void {
    if (process.env.NODE_ENV === "production") {
      this.mixpanel?.track("Click Show Habits");
    }
  }

  trackClickShowTasks(): void {
    if (process.env.NODE_ENV === "production") {
      this.mixpanel?.track("Click Show Tasks");
    }
  }

  trackMakeStep(): void {
    if (process.env.NODE_ENV === "production") {
      this.mixpanel?.track("Make Step");
    }
  }

  trackMarkAsDone(): void {
    if (process.env.NODE_ENV === "production") {
      this.mixpanel?.track("Mark As Done");
    }
  }

  trackGotInstallInfo(): void {
    if (process.env.NODE_ENV === "production") {
      this.mixpanel?.track("Got Install Info");
    }
  }

  trackViewItemsShowCompleted(state: boolean): void {
    if (process.env.NODE_ENV === "production") {
      this.mixpanel?.track("View Items Show Completed", { state });
    }
  }

  trackViewItemsTab(tab: string): void {
    if (process.env.NODE_ENV === "production") {
      this.mixpanel?.track("View Items Tab", { tab });
    }
  }

  trackViewItemsDeleteItem(): void {
    if (process.env.NODE_ENV === "production") {
      this.mixpanel?.track("View Items Delete Item");
    }
  }

  trackViewItemsSelectItem(): void {
    if (process.env.NODE_ENV === "production") {
      this.mixpanel?.track("View Items Select Item");
    }
  }

  trackViewItemClickClose(): void {
    if (process.env.NODE_ENV === "production") {
      this.mixpanel?.track("View Item Click Close");
    }
  }

  trackViewItemClickEdit(): void {
    if (process.env.NODE_ENV === "production") {
      this.mixpanel?.track("View Item Click Edit");
    }
  }

  trackViewItemShare(): void {
    if (process.env.NODE_ENV === "production") {
      this.mixpanel?.track("View Item Share");
    }
  }

  trackEditSave(payload: Record<string, string>): void {
    if (process.env.NODE_ENV === "production") {
      this.mixpanel?.track("Track Edit Save", payload);
    }
  }

  trackNewSave(payload: Record<string, string>): void {
    if (process.env.NODE_ENV === "production") {
      this.mixpanel?.track("Track New Save", payload);
    }
  }

  trackEditBack(): void {
    if (process.env.NODE_ENV === "production") {
      this.mixpanel?.track("Track Edit Back");
    }
  }

  trackNewItemBack(): void {
    if (process.env.NODE_ENV === "production") {
      this.mixpanel?.track("Track New Item Back Navigation");
    }
  }

  trackSettingsBack(): void {
    if (process.env.NODE_ENV === "production") {
      this.mixpanel?.track("Track Settings Back Navigation");
    }
  }

  trackSettingsStartWeekChange(state: string): void {
    if (process.env.NODE_ENV === "production") {
      this.mixpanel?.track("Settings Start Week Change", { state });
    }
  }

  trackSettingsVacation(state: boolean): void {
    if (process.env.NODE_ENV === "production") {
      this.mixpanel?.track("Settings Vacation", { state });
    }
  }

  trackSettingsSounds(state: boolean): void {
    if (process.env.NODE_ENV === "production") {
      this.mixpanel?.track("Settings Sounds", {
        state,
      });
    }
  }

  trackSettingsNotifications(payload: Record<string, string>): void {
    if (process.env.NODE_ENV === "production") {
      this.mixpanel?.track("Settings Notifications", payload);
    }
  }

  trackSettingsClickSendFeedback(): void {
    if (process.env.NODE_ENV === "production") {
      this.mixpanel?.track("Settings Click Send Feedback");
    }
  }

  trackSettingsClickShare(): void {
    if (process.env.NODE_ENV === "production") {
      this.mixpanel?.track("Settings Click Share");
    }
  }

  trackSettingsImprotData(): void {
    if (process.env.NODE_ENV === "production") {
      this.mixpanel?.track("Settings Import Data");
    }
  }

  trackSettingsExportData(): void {
    if (process.env.NODE_ENV === "production") {
      this.mixpanel?.track("Settings Export Data");
    }
  }

  trackSettingsClearAllData(): void {
    if (process.env.NODE_ENV === "production") {
      this.mixpanel?.track("Settings Clear All Data");
    }
  }

  trackSettingsClearAllDataConfirm(): void {
    if (process.env.NODE_ENV === "production") {
      this.mixpanel?.track("Settings Clear All Data Confirm");
    }
  }

  trackSettingsMadeByMe(): void {
    if (process.env.NODE_ENV === "production") {
      this.mixpanel?.track("Settings Made By Me");
    }
  }

  trackAcceptNotifications(): void {
    if (process.env.NODE_ENV === "production") {
      this.mixpanel?.track("Notifications allowed");
    }
  }

  trackDeclineNotifications(): void {
    if (process.env.NODE_ENV === "production") {
      this.mixpanel?.track("Notifications rejected");
    }
  }

  trackSettingsClickPrivacyPolicy(): void {
    if (process.env.NODE_ENV === "production") {
      this.mixpanel?.track("Settings Click Privacy Policy");
    }
  }

  //todo: if we use IOS we need to track notification click
}

const service = new MixpanelService();

export default service;
