import { SafeArea, SafeAreaInsets } from "capacitor-plugin-safe-area";

function setSafeAreaTop(top: number): void {
  document.documentElement.style.setProperty(`--ios-safe-area-top`, `${top}px`);
}

function setSafeAreaBottom(bottom: number): void {
  document.documentElement.style.setProperty(
    `--ios-safe-area-bottom`,
    `${bottom}px`
  );
}

function safeAreaProperies(): void {
  SafeArea.getSafeAreaInsets().then(({ insets }: SafeAreaInsets) => {
    const { top, bottom } = insets;

    setSafeAreaTop(top);
    setSafeAreaBottom(bottom);
  });

  SafeArea.addListener("safeAreaChanged", (data) => {
    const { insets } = data;
    for (const [key, value] of Object.entries(insets)) {
      switch (key) {
        case "top":
          setSafeAreaTop(value);
          break;

        case "bottom":
          setSafeAreaBottom(value);
          break;
      }
    }
  });
}

export default safeAreaProperies;
