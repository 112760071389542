import React, { useEffect, useState } from "react";

const SplashScreen: React.FC<{ animationDone: () => void }> = ({
  animationDone,
}) => {
  const [splashScreenAnimation, setSplashScreenAnimation] =
    useState<boolean>(true);

  useEffect(() => {
    setTimeout(() => {
      setSplashScreenAnimation(false);
      setTimeout(() => {
        animationDone();
      }, 500);
    }, 1000);
  }, [animationDone]);

  return (
    <div className="splash">
      <div
        className="splash__logo"
        style={{
          opacity: splashScreenAnimation ? 1 : 0,
          visibility: splashScreenAnimation ? "visible" : "hidden",
          transition: "all 0.5s ease-in-out",
        }}
      >
        <img src="/logo.png" alt={process.env.REACT_APP_NAME} />{" "}
      </div>
    </div>
  );
};

export default SplashScreen;
