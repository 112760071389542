import dayjs from "dayjs";
import { Habit } from "../../types/habit";
import { History } from "../../types/history";
import { HistoryRecord } from "../../types/history-record";

const todaysHistory = (todaysItems: Habit[], history: History) => {
  return todaysItems.reduce(
    (
      acc: Record<string, HistoryRecord | null>,
      item: { id: string | number }
    ) => {
      if (!history[item.id]) {
        return acc;
      }

      acc[item.id] =
        history[item.id].find((item) => item.date.isSame(dayjs(), "day")) ??
        null;

      return acc;
    },
    {}
  );
};

export default todaysHistory;
