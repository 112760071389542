import { Repeat } from "../../types/repeat";
import { RepeatType } from "../../types/repeat-type";
import { Days } from "../../types/days";

const repeatHandler = (
  period: RepeatType,
  days: Days[],
  week: number | null,
  month: number[]
): Repeat | null => {
  let repeat = null;

  if (period === "daily" && days.length) {
    repeat = {
      type: "daily" as RepeatType,
      moment: days,
    };
  }

  if (period === "weekly" && week !== null) {
    repeat = {
      type: "weekly" as RepeatType,
      moment: week,
    };
  }

  if (period === "monthly" && month.length) {
    repeat = {
      type: "monthly" as RepeatType,
      moment: month,
    };
  }

  return repeat;
};

export default repeatHandler;
