import { LeftOutlined, SaveOutlined } from "@ant-design/icons";
import {
  Button,
  InputRef,
  Popconfirm,
  Typography
} from "antd";
import React, { useEffect, useRef } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import ItemForm from "../../core/components/item-form/item-form";
import { ContentStyled, Layout } from "../../core/components/layout/layout";
import repeatHandler from "../../core/utils/repeat-handler";
import mixpanelService from "../../services/mixpanel-service";
import useHabitStore from "../../store/habit-store";
import useItemStore from "../../store/item-store";
import { Days } from "../../types/days";
import { Habit } from "../../types/habit";

const { Title } = Typography;

const EditItem: React.FC = () => {
  const navigate = useNavigate();
  const { item } = useLoaderData() as { item: Habit };
  const inputRef = useRef<InputRef>(null);
  const { updateItem, reset } = useHabitStore.getState();
  const { clear } = useItemStore.getState();

  const name = useItemStore((state) => state.name);
  const description = useItemStore((state) => state.description);
  const touched = useItemStore((state) => state.touched);
  const period = useItemStore((state) => state.period);
  const week = useItemStore((state) => state.week);
  const month = useItemStore((state) => state.month);
  const days = useItemStore((state) => state.days);
  const endDate = useItemStore((state) => state.endDate);
  const icon = useItemStore((state) => state.icon);
  const count = useItemStore((state) => state.count);
  const unit = useItemStore((state) => state.unit);
  const goal = useItemStore((state) => state.goal);

  const {
    setId,
    setName,
    setDescription,
    setTouched,
    setPeriod,
    setWeek,
    setMonth,
    setDays,
    setEndDate,
    setIcon,
    setCount,
    setUnit,
    setGoal,
  } = useItemStore.getState();

  useEffect(() => {
    setId(item.id);
    setName(item.title);
    setDescription(item.description);
    setPeriod(item.repeat?.type ?? "daily");

    switch (item.repeat?.type) {
      case "daily": {
        setDays(item.repeat?.moment as Days[]);
        setWeek(null);
        setMonth([]);
        break;
      }

      case "weekly": {
        setDays([]);
        setWeek((item.repeat?.moment ?? null) as number | null);
        setMonth([]);
        break;
      }

      case "monthly": {
        setDays([]);
        setWeek(null);
        setMonth(item.repeat?.moment as number[]);
        break;
      }

      default: {
        setDays([]);
        setWeek(null);
        setMonth([]);
      }
    }

    setEndDate(item.endDate ?? null);
    setIcon(item.icon);
    setCount(item.goal?.unit.count ?? 1);
    setUnit(item.goal?.unit.type ?? "times");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    inputRef?.current?.focus();
  }, []);

  useEffect(() => {
    if (name !== null) {
      setTouched(true);
    }
  }, [name, setTouched]);

  useEffect(() => {
    return () => {
      clear();
    };
  }, [clear]);

  const save = () => {
    const data = {
      title: name as string,
      description,
      icon,
      goal: {
        unit: {
          type: unit,
          count,
        },
      },
      repeat: repeatHandler(period, days, week, month),
      endDate,
    };

    updateItem(item.id, data);
    reset(item.id);
    navigate(-1);

    mixpanelService.trackEditSave({
      title: name as string,
      description,
      icon,
      goalType: unit,
      goalCount: count.toString(),
      repeatType: data.repeat?.type ?? "",
      repeatMoment: data.repeat?.moment?.toString() ?? "",
      endDate: endDate?.format("DD mmm YYYY") ?? "",
    });
  };

  return (
    <Layout
      header={
        <>
          <Button
            icon={<LeftOutlined />}
            type="text"
            shape="circle"
            size="large"
            onClick={() => {
              navigate(-1);
              mixpanelService.trackEditBack();
            }}
          />
          <Title
            level={3}
            style={{ flex: 1, margin: 0, textAlign: "center" }}
            ellipsis={{ rows: 1 }}
          >
            {item.repeat ? "Edit habit" : "Edit task"}
          </Title>
          <Popconfirm
            placement="bottomRight"
            title={`Are you sure you want to change this ${
              item.repeat ? "habit" : "task"
            }?`}
            description="It will reset you current progress."
            okText="Yes"
            cancelText="No"
            onConfirm={() => {
              save();
            }}
          >
            <Button
              type="text"
              shape="circle"
              size="large"
              icon={<SaveOutlined />}
              disabled={!name}
            />
          </Popconfirm>
        </>
      }
      content={
        <ContentStyled>
          <ItemForm
            name={name}
            description={description}
            touched={touched}
            period={period}
            days={days}
            week={week}
            month={month}
            count={count}
            unit={unit}
            endDate={endDate}
            goal={goal}
            icon={icon}
            setName={setName}
            setDescription={setDescription}
            setTouched={setTouched}
            setPeriod={setPeriod}
            setDays={setDays}
            setWeek={setWeek}
            setMonth={setMonth}
            setCount={setCount}
            setUnit={setUnit}
            setEndDate={setEndDate}
            setGoal={setGoal}
            setIcon={setIcon}
          />
        </ContentStyled>
      }
    ></Layout>
  );
};

export default EditItem;
