import { Button, Space } from "antd";
import dayjs, { Dayjs } from "dayjs";
import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { useDays } from "./use-days";

const StyledSpace = styled(Space)`
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  width: 100%;
  max-width: var(--max-width);
  padding-top: 16px;
  padding-bottom: 16px;
  margin-top: calc(64px + var(--safe-area-top));
  position: fixed;
  z-index: 1;
  top: 0;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 0;
    height: 0;
  }
`;

const Calendar: React.FC<{
  currentSelection: Dayjs;
  onSmash: (day: Dayjs) => void;
}> = ({ onSmash, currentSelection }) => {
  const days = useDays();
  const currentSelectionButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    currentSelectionButtonRef.current?.scrollIntoView({
      block: "nearest",
      inline: "start",
      behavior: "smooth",
    });
  });

  const getButtonType = (date: Dayjs) => {
    if (date.isToday()) {
      return "primary";
    }

    if (date.isSame(currentSelection, "day")) {
      return "default";
    }

    if (dayjs().isSame(date, "month")) {
      return "text";
    }

    return "dashed";
  };

  return (
    <StyledSpace direction="horizontal" size="small">
      {days.map((date, index) => (
        <Button
          key={index}
          shape="round"
          size="middle"
          type={getButtonType(date)}
          ref={
            date.isSame(currentSelection.add(-1, "day"), "day")
              ? currentSelectionButtonRef
              : null
          }
          onClick={() => onSmash(date)}
        >
          {`${date.format("D")} ${date.format("ddd")}`}
        </Button>
      ))}
    </StyledSpace>
  );
};

export default Calendar;
