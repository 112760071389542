import { LeftOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, InputRef, Typography } from "antd";
import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import ItemForm from "../../core/components/item-form/item-form";
import { ContentStyled, Layout } from "../../core/components/layout/layout";
import repeatHandler from "../../core/utils/repeat-handler";
import mixpanelService from "../../services/mixpanel-service";
import useHabitStore from "../../store/habit-store";
import useItemStore from "../../store/item-store";

const { Title } = Typography;

const NewItem: React.FC = () => {
  const navigate = useNavigate();
  const { addItem } = useHabitStore.getState();
  const { clear } = useItemStore.getState();

  const name = useItemStore((state) => state.name);
  const description = useItemStore((state) => state.description);
  const touched = useItemStore((state) => state.touched);
  const period = useItemStore((state) => state.period);
  const week = useItemStore((state) => state.week);
  const month = useItemStore((state) => state.month);
  const days = useItemStore((state) => state.days);
  const endDate = useItemStore((state) => state.endDate);
  const icon = useItemStore((state) => state.icon);
  const count = useItemStore((state) => state.count);
  const unit = useItemStore((state) => state.unit);
  const goal = useItemStore((state) => state.goal);

  const {
    setName,
    setTouched,
    setPeriod,
    setWeek,
    setMonth,
    setDays,
    setEndDate,
    setIcon,
    setCount,
    setUnit,
    setGoal,
    setDescription,
  } = useItemStore.getState();

  const inputRef = useRef<InputRef>(null);

  useEffect(() => {
    inputRef?.current?.focus();
  }, []);

  useEffect(() => {
    if (name !== null) {
      setTouched(true);
    }
  }, [name, setTouched]);

  useEffect(() => {
    return () => {
      clear();
    };
  }, [clear]);

  const save = () => {
    const data = {
      title: name as string,
      description,
      icon,
      goal: {
        unit: {
          type: unit,
          count,
        },
      },
      repeat: repeatHandler(period, days, week, month),
      endDate,
    };

    addItem(data);

    navigate("/");

    mixpanelService.trackNewSave({
      title: name as string,
      description,
      icon,
      goalType: unit,
      goalCount: count.toString(),
      repeatType: data.repeat?.type ?? "",
      repeatMoment: data.repeat?.moment?.toString() ?? "",
      endDate: endDate?.format("DD mmm YYYY") ?? "",
    });
  };

  return (
    <Layout
      header={
        <>
          <Button
            icon={<LeftOutlined />}
            type="text"
            shape="circle"
            size="large"
            onClick={() => {
              navigate("/");
              mixpanelService.trackNewItemBack();
            }}
          />
          <Title
            level={3}
            style={{ flex: 1, margin: 0, textAlign: "center" }}
            ellipsis={{ rows: 1 }}
          >
            {days.length || !!week || month.length ? "New habit" : "New task"}
          </Title>
          <Button
            type="text"
            shape="circle"
            size="large"
            icon={<SaveOutlined />}
            disabled={!name}
            onClick={() => save()}
          />
        </>
      }
      content={
        <ContentStyled>
          <ItemForm
            name={name}
            description={description}
            touched={touched}
            period={period}
            days={days}
            week={week}
            month={month}
            count={count}
            unit={unit}
            endDate={endDate}
            goal={goal}
            icon={icon}
            setName={setName}
            setDescription={setDescription}
            setTouched={setTouched}
            setPeriod={setPeriod}
            setDays={setDays}
            setWeek={setWeek}
            setMonth={setMonth}
            setCount={setCount}
            setUnit={setUnit}
            setEndDate={setEndDate}
            setGoal={setGoal}
            setIcon={setIcon}
          />
        </ContentStyled>
      }
    ></Layout>
  );
};

export default NewItem;
