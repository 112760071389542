import React from "react";
import { isRouteErrorResponse, useRouteError } from "react-router-dom";
import ErrorPage from "./error-page";
import NotFound from "./not-found";

const ErrorBoundary: React.FC = () => {
  const error = useRouteError();

  console.error(error);

  if (isRouteErrorResponse(error) && error.status === 404) {
    return <NotFound />;
  }

  return <ErrorPage />;
};

export default ErrorBoundary;
