import { useEffect, useState } from "react";
import useHabitStore from "../store/habit-store";
import useNotificationStore from "../store/notification-store";
import useSettingsStore from "../store/settings-store";

const useHydrationOnce = () => {
  const [habitHydrated, setHabitHydrated] = useState(false);
  const [notificationHydrated, setNotificationHydrated] = useState(false);
  const [settingsHydrated, setSettingsHydrated] = useState(false);

  useEffect(() => {
    const unsubFinishHydration = useHabitStore.persist.onFinishHydration(() =>
      setHabitHydrated(true)
    );

    setHabitHydrated(useHabitStore.persist.hasHydrated());

    const unsubNotification = useNotificationStore.persist.onFinishHydration(
      () => setNotificationHydrated(true)
    );

    setNotificationHydrated(useNotificationStore.persist.hasHydrated());

    const unsubSettings = useSettingsStore.persist.onFinishHydration(() =>
      setSettingsHydrated(true)
    );

    setSettingsHydrated(useSettingsStore.persist.hasHydrated());

    return () => {
      unsubFinishHydration();
      unsubNotification();
      unsubSettings();
    };
  }, [setHabitHydrated, setNotificationHydrated]);

  return habitHydrated && notificationHydrated && settingsHydrated;
};

export default useHydrationOnce;

export const hydrationOnce = Promise.all([
  new Promise((resolve) => useHabitStore.persist.onFinishHydration(resolve)),
  new Promise((resolve) =>
    useNotificationStore.persist.onFinishHydration(resolve)
  ),
  new Promise((resolve) => useSettingsStore.persist.onFinishHydration(resolve)),
]);
