import dayjs from "dayjs";

export const getUnitsFormatted = (count: number, unit: string): string => {
  switch (unit) {
    case "pages": {
      if (count === 1) {
        return "1 page";
      }

      return `${count} pages`;
    }

    case "glasses": {
      if (count === 1) {
        return "1 glass";
      }

      return `${count} glasses`;
    }

    case "times": {
      if (count === 1) {
        return "1 time";
      }

      return `${count} times`;
    }

    case "kilometers": {
      if (count === 1) {
        return "1 kilometer";
      }

      return `${count} kilometers`;
    }

    case "timer": {
      const duration = dayjs.duration({
        seconds: count,
      });

      return duration.humanize();
    }
  }

  return `${count} ${unit}`;
};
