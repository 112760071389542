import { json, LoaderFunction } from "react-router-dom";
import { hydrationOnce } from "../../services/use-hydration-once";
import useHabitStore from "../../store/habit-store";
import { Habit } from "../../types/habit";

const itemLoader: LoaderFunction<{ id: string }> = async ({
  params,
}): Promise<{
  item: Habit | null;
}> => {
  await hydrationOnce;
  const { getItem } = useHabitStore.getState();

  const item = getItem(params.id as string);

  if (!item) {
    throw json({ message: "Item not found" }, { status: 404 });
  }

  return { item };
};

export default itemLoader;
