import {
  ClearOutlined,
  ExportOutlined,
  HeartTwoTone,
  ImportOutlined,
  LeftOutlined,
  MailOutlined,
  RightOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import {
  Button,
  Flex,
  Modal,
  Popconfirm,
  Switch,
  Typography,
  message,
} from "antd";
import dayjs from "dayjs";
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ContentStyled, Layout } from "../../core/components/layout/layout";
import { weekDays } from "../../core/consts/weekdays";
import { dateDeserialize } from "../../core/utils/date-deserializer";
import mixpanelService from "../../services/mixpanel-service";
import notificationService, {
  default as NotificationService,
} from "../../services/notification-service";
import settingsService from "../../services/settings-service";
import { useInstalledStatus } from "../../services/use-installed-status";
import useHabitStore from "../../store/habit-store";
import useNotificationStore from "../../store/notification-store";
import useSettingsStore from "../../store/settings-store";

const { Title, Text } = Typography;

const Settings: React.FC = () => {
  const requestSubscriptionDeffer = useRef<Promise<void> | null>(null);
  const installed = useInstalledStatus();
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const {
    setVacationMode,
    setSounds,
    setPlanOfDayTime,
    setDaySummaryTime,
    setWeekStartDay,
    setNotificationPermission,
    bulkUpdate,
  } = useSettingsStore.getState();

  const { clear: clearHabits, bulkAdd } = useHabitStore.getState();
  const { clear: clearNotifications } = useNotificationStore.getState();

  const vacation = useSettingsStore(
    (state) => !!state.vacationHistory.find(([, endAt]) => !endAt)
  );
  const weekStartDay = useSettingsStore((state) => state.weekStartDay);
  const sounds = useSettingsStore((state) => state.sounds);
  const planOfWeekTime = useSettingsStore((state) => state.planOfWeekTime);
  const planOfDayTime = useSettingsStore((state) => state.planOfDayTime);
  const weekSummaryTime = useSettingsStore((state) => state.weekSummaryTime);
  const daySummaryTime = useSettingsStore((state) => state.daySummaryTime);
  const notificationPermission = useSettingsStore(
    (state) => state.notificationPermission
  );

  const [notificationModalOpen, setNotificationModalOpen] = useState(false);
  const [weekdaysModalOpen, setWeekdaysModalOpen] = useState(false);

  const shareApp = () => {
    mixpanelService.trackSettingsClickShare();

    if (navigator.share) {
      navigator
        .share({
          title: process.env.REACT_APP_NAME,
          text: "Check out this awesome app!",
          url: `https://${process.env.REACT_APP_DOMAIN}`,
        })
        .catch((error) => console.log("Error sharing", error));
      return;
    }

    navigator.clipboard
      .writeText(
        `Check out this awesome app!\nhttps://${process.env.REACT_APP_DOMAIN}\n#${process.env.REACT_APP_NAME}`
      )
      .then(() => {
        return messageApi.open({
          type: "success",
          content: "Copied to clipboard!",
          style: {
            marginTop: "var(--safe-area-top)",
          },
        });
      })
      .catch(() => {
        return messageApi.open({
          type: "warning",
          content: "Could not copy to clipboard!",
          style: {
            marginTop: "var(--safe-area-top)",
          },
        });
      });
  };

  const handleNotificationButtonClick = async () => {
    setNotificationModalOpen(true);

    if (!installed) {
      return;
    }

    if (requestSubscriptionDeffer.current) {
      return;
    }

    requestSubscriptionDeffer.current =
      NotificationService.requestNotificationSubscription().then(
        (status: boolean): void => {
          setNotificationPermission(status);
        }
      );
  };

  const defaultTime = dayjs.duration({ hours: 9 }).asSeconds();

  const clearAllData = () => {
    mixpanelService.trackSettingsClearAllDataConfirm();
    clearHabits();
    clearNotifications();

    messageApi.open({
      type: "success",
      content: "All data cleared!",
      style: {
        marginTop: "var(--safe-area-top)",
      },
    });
  };

  const importAllData = () => {
    mixpanelService.trackSettingsImprotData();

    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = ".txt";
    fileInput.style.display = "none";
    document.body.appendChild(fileInput);

    fileInput.addEventListener("change", (event) => {
      const selectedFile = (event.target as HTMLInputElement).files?.[0];

      const reader = new FileReader();
      reader.onload = (event) => {
        const text = event.target?.result as string;
        const json = JSON.parse(text, dateDeserialize);

        bulkAdd(json.habits.allItems, json.habits.history);
        bulkUpdate(json.settings);

        messageApi.open({
          type: "success",
          content: "Import successful",
          style: {
            marginTop: "var(--safe-area-top)",
          },
        });
      };

      if (selectedFile) {
        reader.readAsText(selectedFile);
      }

      fileInput.remove();
    });

    fileInput.click();
  };

  const exportAllData = () => {
    mixpanelService.trackSettingsExportData();

    const habits = useHabitStore.getState();
    const settings = useSettingsStore.getState();

    const json = {
      habits: {
        allItems: habits.allItems,
        history: habits.history,
      },
      settings: {
        ...settings,
        vacationHistory: [],
      },
    };

    settingsService
      .exportAllData(JSON.stringify(json))
      .then(() => {
        messageApi.open({
          type: "success",
          content: "Export successful",
          style: {
            marginTop: "var(--safe-area-top)",
          },
        });
      })
      .catch((e) => console.warn(e));
  };

  return (
    <Layout
      header={
        <>
          <Button
            icon={<LeftOutlined />}
            type="text"
            shape="circle"
            size="large"
            onClick={() => {
              navigate("/");
              mixpanelService.trackSettingsBack();
            }}
          />
          <Title
            level={3}
            style={{
              flex: 1,
              margin: 0,
              textAlign: "center",
              marginLeft: "-40px",
            }}
          >
            Settings
          </Title>
        </>
      }
      content={
        <ContentStyled>
          <Flex
            vertical
            gap="large"
            align="stretch"
            style={{ paddingBottom: 300 }}
          >
            <Flex
              justify="space-between"
              align="center"
              style={{ height: "34px" }}
            >
              <Flex vertical>
                <Text strong>Start week on</Text>
                <Text
                  type="secondary"
                  style={{ fontSize: "12px", lineHeight: "12px" }}
                >
                  {weekStartDay
                    .slice(0, 1)
                    .toUpperCase()
                    .concat(weekStartDay.slice(1))}
                </Text>
              </Flex>

              <Button
                icon={<RightOutlined />}
                type="text"
                onClick={() => setWeekdaysModalOpen(true)}
              />
            </Flex>

            <Flex
              justify="space-between"
              align="center"
              style={{ height: "34px" }}
            >
              <Flex vertical>
                <Text strong>Vacation</Text>
                <Text
                  type="secondary"
                  style={{ fontSize: "12px", lineHeight: "12px" }}
                >
                  Pause all notifications and statistics
                </Text>
              </Flex>
              <Switch
                onChange={(event) => {
                  setVacationMode(event);
                  notificationService.handleScheduleChange(
                    event,
                    !!planOfDayTime,
                    !!daySummaryTime
                  );
                  mixpanelService.trackSettingsVacation(event);
                }}
                value={vacation}
              />
            </Flex>

            <Flex
              justify="space-between"
              align="center"
              style={{ height: "34px" }}
            >
              <Text strong>Sounds</Text>
              <Switch
                value={sounds}
                onChange={(event) => {
                  setSounds(event);
                  mixpanelService.trackSettingsSounds(event);
                }}
              />
            </Flex>

            <Flex
              justify="space-between"
              align="center"
              style={{ height: "34px" }}
            >
              <Flex vertical>
                <Text strong>Notifications</Text>
                <Text
                  type="secondary"
                  style={{ fontSize: "12px", lineHeight: "12px" }}
                >
                  Enable notifications for updates about your plans
                </Text>
              </Flex>

              <Button
                icon={<RightOutlined />}
                type="text"
                onClick={handleNotificationButtonClick}
              />
            </Flex>

            <Flex
              justify="space-between"
              align="center"
              style={{ height: "34px" }}
            >
              <Text strong>Send feedback</Text>
              <Button
                icon={<MailOutlined />}
                type="text"
                onClick={() => {
                  mixpanelService.trackSettingsClickSendFeedback();
                  settingsService.sendFeedback();
                }}
              />
            </Flex>

            <Flex
              justify="space-between"
              align="center"
              style={{ height: "34px" }}
            >
              <Text strong>Share</Text>
              <Button
                icon={<ShareAltOutlined />}
                type="text"
                onClick={() => shareApp()}
              />
            </Flex>

            <Flex
              justify="space-between"
              align="center"
              style={{ height: "34px" }}
            >
              <Text strong>Privacy Policy</Text>
              <Button
                style={{ minWidth: 32 }}
                icon={<ExportOutlined />}
                type="text"
                target="_blank"
                onClick={() => {
                  mixpanelService.trackSettingsClickPrivacyPolicy();
                  settingsService.openLink(
                    process.env.REACT_APP_PRIVACY_POLICY!
                  );
                }}
              />
            </Flex>

            <Flex
              justify="space-between"
              align="center"
              style={{ height: "34px" }}
            >
              <Flex vertical>
                <Text strong>Import data</Text>

                <Text
                  type="secondary"
                  style={{ fontSize: "12px", lineHeight: "12px" }}
                >
                  Select a file to import data. This will override the current
                  state. Action is undone.
                </Text>
              </Flex>
              <Button
                style={{ minWidth: 32 }}
                icon={<ImportOutlined />}
                type="text"
                onClick={() => importAllData()}
              />
            </Flex>

            <Flex
              justify="space-between"
              align="center"
              style={{ height: "34px" }}
            >
              <Flex vertical>
                <Text strong>Export data</Text>
                <Text
                  type="secondary"
                  style={{ fontSize: "12px", lineHeight: "12px" }}
                >
                  It allows you to back up your data and easily re-import it in
                  the future.
                </Text>
              </Flex>
              <Button
                style={{ minWidth: 32 }}
                icon={<ExportOutlined />}
                type="text"
                onClick={() => exportAllData()}
              />
            </Flex>

            <Flex
              justify="space-between"
              align="center"
              style={{ height: "34px" }}
            >
              <Flex vertical>
                <Text strong>Clear all data</Text>
                <Text
                  type="secondary"
                  style={{ fontSize: "12px", lineHeight: "12px" }}
                >
                  Delete all data immediately
                </Text>
              </Flex>
              <Popconfirm
                title="Clear all data"
                description="Are you sure? This action is undone."
                placement="left"
                onConfirm={clearAllData}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  icon={<ClearOutlined />}
                  type="text"
                  onClick={() => {
                    mixpanelService.trackSettingsClearAllData();
                  }}
                />
              </Popconfirm>
            </Flex>
          </Flex>

          <Flex
            vertical
            justify="center"
            align="center"
            style={{ paddingBottom: 16 }}
          >
            <Text type="secondary">Made by</Text>
            <Button
              type="link"
              href={`https://${process.env.REACT_APP_AUTHOR_DOMAIN}`}
              target="_blank"
              onClick={() => {
                mixpanelService.trackSettingsMadeByMe();
              }}
            >
              https://{process.env.REACT_APP_AUTHOR_DOMAIN}
            </Button>
            <Text type="secondary">
              with <HeartTwoTone />
            </Text>
          </Flex>
          <Modal
            centered
            title="Start week on"
            open={weekdaysModalOpen}
            footer={null}
            onCancel={() => setWeekdaysModalOpen(false)}
          >
            <Flex align="center" vertical>
              {weekDays.map(({ key }) => (
                <Button
                  key={key}
                  type={key === weekStartDay ? "primary" : "text"}
                  style={{ textTransform: "capitalize" }}
                  onClick={() => {
                    setWeekStartDay(key);
                    setWeekdaysModalOpen(false);
                    mixpanelService.trackSettingsStartWeekChange(key);
                  }}
                >
                  {key}
                </Button>
              ))}
            </Flex>
          </Modal>

          <Modal
            centered
            title={
              <Flex vertical gap="small">
                <Title level={5} style={{ margin: 0 }}>
                  Notifications
                </Title>
                <Text
                  type="secondary"
                  style={{ lineHeight: "12px", fontSize: 12 }}
                >
                  {installed
                    ? !notificationPermission
                      ? "Please enable notifications in the app settings to receive updates."
                      : "Select the type of notifications you would like to receive."
                    : "Please install the app to enable notifications."}
                </Text>
              </Flex>
            }
            open={notificationModalOpen}
            footer={null}
            onCancel={() => {
              setNotificationModalOpen(false);
              notificationService.handleScheduleChange(
                vacation,
                !!planOfDayTime,
                !!daySummaryTime
              );

              mixpanelService.trackSettingsNotifications({
                planOfDayTime: planOfDayTime?.toString() ?? "",
                daySummaryTime: daySummaryTime?.toString() ?? "",
                planOfWeekTime: planOfWeekTime?.toString() ?? "",
                weekSummaryTime: weekSummaryTime?.toString() ?? "",
              });
            }}
          >
            <Flex vertical gap="middle">
              <Flex
                justify="space-between"
                align="center"
                style={{ minHeight: "32px" }}
                gap="small"
                wrap="wrap"
              >
                <Text>Plan of day (8:30)</Text>
                <Switch
                  value={!!planOfDayTime}
                  onChange={(checked: boolean) =>
                    setPlanOfDayTime(checked ? defaultTime : null)
                  }
                  disabled={!installed}
                />

                {/* {!!planOfDayTime && (
              <Flex
                justify="space-between"
                align="center"
                style={{ flexBasis: "100%", padding: "0 0 16px 16px" }}
              >
                <Tooltip title="This feature will be available in future releases">
                  <Text type="secondary">On a day at</Text>
                </Tooltip>
                <TimePicker
                  disabled
                  value={dayjs().startOf("day").add(planOfDayTime, "seconds")}
                  onChange={(value) => {
                    setPlanOfDayTime(
                      value
                        ? dayjs
                            .duration({
                              hours: value.hour() ?? 0,
                              minutes: value.minute() ?? 0,
                            })
                            .asSeconds()
                        : null
                    );
                  }}
                />
              </Flex>
            )} */}
              </Flex>

              <Flex
                justify="space-between"
                align="center"
                style={{ minHeight: "32px" }}
                gap="small"
                wrap="wrap"
              >
                <Text>Day summary (21:00)</Text>
                <Switch
                  value={!!daySummaryTime}
                  onChange={(checked: boolean): void =>
                    setDaySummaryTime(checked ? defaultTime : null)
                  }
                  disabled={!installed}
                />

                {/* {!!daySummaryTime && (
              <Flex
                justify="space-between"
                align="center"
                style={{ flexBasis: "100%", padding: "0 0 16px 16px" }}
              >
                <Tooltip title="This feature will be available in future releases">
                  <Text type="secondary">On a day at</Text>
                </Tooltip>

                <TimePicker
                  disabled
                  value={dayjs().startOf("day").add(daySummaryTime, "seconds")}
                  onChange={(value) => {
                    setDaySummaryTime(
                      value
                        ? dayjs
                            .duration({
                              hours: value.hour() ?? 0,
                              minutes: value.minute() ?? 0,
                            })
                            .asSeconds()
                        : null
                    );
                  }}
                />
              </Flex>
            )} */}
              </Flex>

              {/* <Flex
            justify="space-between"
            align="center"
            style={{ minHeight: "32px" }}
            gap="small"
            wrap="wrap"
          >
            <Text>Plan of week</Text>

            <Switch
              value={!!planOfWeekTime}
              onChange={(checked: boolean) =>
                setPlanOfWeekTime(checked ? defaultTime : null)
              }
              // disabled={!installed}
              disabled
            />
            {!!planOfWeekTime && (
              <Flex
                justify="space-between"
                align="center"
                style={{ flexBasis: "100%", padding: "0 0 16px 16px" }}
              >
                <Text type="secondary">
                  On{" "}
                  <span style={{ textTransform: "capitalize" }}>
                    {weekStartDay}
                  </span>{" "}
                  at
                </Text>
                <TimePicker
                  disabled
                  value={dayjs().startOf("day").add(planOfWeekTime, "seconds")}
                  onChange={(value) => {
                    setPlanOfWeekTime(
                      value
                        ? dayjs
                            .duration({
                              hours: value.hour() ?? 0,
                              minutes: value.minute() ?? 0,
                            })
                            .asSeconds()
                        : null
                    );
                  }}
                />
              </Flex>
            )}
          </Flex> */}

              {/* <Flex
            justify="space-between"
            align="center"
            style={{ minHeight: "32px" }}
            gap="small"
            wrap="wrap"
          >
            <Text>Week summary</Text>

            <Switch
              // disabled={!installed}
              disabled
              value={!!weekSummaryTime}
              onChange={(checked: boolean) =>
                setWeekSummaryTime(checked ? defaultTime : null)
              }
            />

            {!!weekSummaryTime && (
              <Flex
                justify="space-between"
                align="center"
                style={{ flexBasis: "100%", padding: "0 0 16px 16px" }}
              >
                <Text type="secondary">On a day at</Text>
                <TimePicker
                  disabled
                  value={dayjs().startOf("day").add(weekSummaryTime, "seconds")}
                  onChange={(value) => {
                    setWeekSummaryTime(
                      value
                        ? dayjs
                            .duration({
                              hours: value.hour() ?? 0,
                              minutes: value.minute() ?? 0,
                            })
                            .asSeconds()
                        : null
                    );
                  }}
                />
              </Flex>
            )}
          </Flex> */}
            </Flex>
          </Modal>

          {contextHolder}
        </ContentStyled>
      }
    ></Layout>
  );
};

export default Settings;
