class EventBusService {
  private workerChannel = new BroadcastChannel("sw-messages");

  connect = (): {
    listen: (event: string, fn: (payload?: unknown) => void) => void;
  } => {
    this.workerChannel.addEventListener("message", (event) => {
      const message = {
        type: event.data.type,
        payload: JSON.parse(event.data.payload),
      };

      window.postMessage(message);
    });

    return {
      listen: (name: string, fn: (payload?: unknown) => void): void => {
        window.addEventListener("message", (event) => {
          if (event.data.type === name) {
            fn(event.data.payload);
          }
        });
      },
    };
  };
}
const service = new EventBusService();

export default service;
