import { Habit } from "../../types/habit";
import { HistoryRecord } from "../../types/history-record";
import { NotificationBody } from "../../types/notification";

const buildDailyPlanBody = (todaysItems: Habit[]): NotificationBody => {
  return !!todaysItems.length
    ? {
        header: `Your plan for today:`,
        items: todaysItems.map((item: Habit) => item.title),
        footer: "Have a great one!",
      }
    : {
        header: "No plan for today. Create some new habits!",
      };
};

const buildDailySummaryBody = (
  todaysItems: Habit[],
  todaysHistory: Record<string, HistoryRecord | null>
): NotificationBody => {
  return !!todaysItems.length
    ? {
        header: "Carpe diem and take control of your day!",
        items: todaysItems.map(
          (item) =>
            `${item.title} - ${
              todaysHistory[item.id]?.done ? "Done" : "You still can make it!"
            }`
        ),
        footer: todaysItems.every((item) => !!todaysHistory[item.id]?.done)
          ? "Congratulations! You've completed all your habits for today!"
          : "Looks like some of your habits need more attention next time.",
      }
    : {
        header:
          "Looks like you have a free day today! Why not create some new habits or tasks?",
      };
};

export { buildDailyPlanBody, buildDailySummaryBody };
