import { Habit } from "../../types/habit";
import { Dayjs } from "dayjs";
import dateValidation from "./date-validation";

const filterItems = (items: Habit[], date: Dayjs): Habit[] => {
  return items.filter((habit) => {
    const { repeat, endDate } = habit;

    return dateValidation(date, habit.createdAt, repeat, endDate);
  });
};

export default filterItems;
