import React from "react";
import { Button, Flex } from "antd";

type MonthProps = {
  selected: number[];
  onChange: (value: number[]) => void;
};

const Month: React.FC<MonthProps> = ({ selected, onChange }) => {
  const toggleState = (day: number) => {
    if (selected.includes(day)) {
      onChange(selected.filter((item) => item !== day).sort((a, b) => a - b));
    } else {
      onChange([...selected, day].sort((a, b) => a - b));
    }
  };

  return (
    <Flex align="center" gap="middle" justify="center" wrap="wrap">
      {new Array(31).fill(null).map((_, index) => {
        const day = index + 1;

        return (
          <Button
            type={selected.includes(day) ? "primary" : "default"}
            shape="circle"
            size="middle"
            key={day}
            onClick={() => toggleState(day)}
          >
            {day}
          </Button>
        );
      })}
    </Flex>
  );
};

export default Month;
