import { Badge, Card, Flex, Typography } from "antd";
import { Dayjs } from "dayjs";
import React from "react";

const { Text, Paragraph } = Typography;

interface NotificationCardProps {
  title: string;
  subtitle?: {
    header: string;
    items?: string[];
    footer?: string;
  };
  icon: string;
  time: Dayjs;
  isNew: boolean;
  onClick: () => void;
}

const NotificationCard: React.FC<NotificationCardProps> = ({
  title,
  subtitle,
  icon,
  time,
  isNew,
  onClick,
}) => {
  return (
    <Badge dot={isNew} offset={[-10, 10]} styles={{ root: { width: "100%" } }}>
      <Card
        onClick={onClick}
        style={{ width: "100%" }}
        bordered={false}
        size="small"
      >
        <Flex align="center" justify="space-between">
          <Text ellipsis strong>
            {title}
          </Text>
          <Text
            type="secondary"
            style={{
              fontSize: "12px",
            }}
          >
            {time.format("HH:mm DD.MM.YYYY")}
          </Text>
        </Flex>

        <Flex gap="small" align="center">
          {React.cloneElement(<div>{icon}</div>, {
            style: {
              fontSize: "30px",
              height: "100%",
              alignItems: "center",
              lineHeight: "30px",
              overflow: "hidden",
              minWidth: "35px",
            },
          })}

          {subtitle && (
            <Paragraph type="secondary" style={{ marginBottom: 0 }}>
              <figure>
                <figcaption>
                  <strong>{subtitle.header}</strong>
                </figcaption>
                {!!subtitle.items?.length ? (
                  <ul>
                    {subtitle.items.map((item) => (
                      <li key={item}>{item}</li>
                    ))}
                  </ul>
                ) : null}
              </figure>

              {subtitle.footer}
            </Paragraph>
          )}
        </Flex>
      </Card>
    </Badge>
  );
};

export default NotificationCard;
