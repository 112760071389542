import { RepeatType } from "../../types/repeat-type";
import { Days } from "../../types/days";

export const getPeriodFormatted = (
  period: RepeatType,
  moment: Days[] | number[] | number | null
): string => {
  switch (period) {
    case "weekly": {
      moment = moment as number | null;

      if (moment === null) {
        return "Do not repeat";
      }

      return `${moment} times a week`;
    }

    case "monthly": {
      moment = moment as number[];

      if (moment.length === 0) {
        return "Do not repeat";
      }

      if (moment.length === 31) {
        return "Every day";
      }

      return `Every ${moment.join(", ")} day of month`;
    }

    case "daily":
    default: {
      moment = moment as Days[];

      if (moment.length === 0) {
        return "Do not repeat";
      }

      if (moment.length === 7) {
        return "Every day";
      }

      return `Every ${moment
        .map((item) => item.slice(0, 1).toUpperCase().concat(item.slice(1)))
        .join(", ")}`;
    }
  }
};
