import { useRouteError } from "react-router-dom";
import React from "react";
import { Button, Result } from "antd";

const ErrorPage: React.FC = () => {
  const error = useRouteError() as {
    statusText: string;
    message: string;
  } | null;

  console.warn(error);

  return (
    <Result
      status="500"
      title="500"
      subTitle="Sorry, something went wrong."
      extra={
        <Button type="primary" href="/">
          Back Home
        </Button>
      }
    />
  );
};

export default ErrorPage;
