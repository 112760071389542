import { Layout as AntLayout } from "antd";

import React from "react";
import styled from "styled-components";
const { Header, Footer, Content } = AntLayout;

const HeaderHomeStyled = styled(Header)`
  margin-top: var(--safe-area-top);
  position: fixed;
  z-index: 10;
  width: 100%;
  max-width: var(--max-width);
  display: flex;
  align-items: center;
  padding: 16px;
  background: transparent;
`;

const FooterHomeStyled = styled(Footer)`
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: var(--max-width);
  display: flex;
  justify-content: space-around;
  padding-left: 0;
  padding-right: 0;
  background: transparent;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  padding-bottom: calc(var(--safe-area-bottom) + 24px);
`;

const HeaderStyled = styled(Header)`
  position: fixed;
  z-index: 10;
  width: 100%;
  max-width: var(--max-width);
  display: flex;
  align-items: center;
  background: transparent;
  padding: calc(var(--safe-area-top) + 16px) 16px 16px;
  height: calc(64px + var(--safe-area-top));
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
`;

const FooterStyled = styled(Footer)`
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: var(--max-width);
  display: flex;
  justify-content: space-evenly;
  padding-left: 0;
  padding-right: 0;
  background: transparent;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  padding-bottom: calc(var(--safe-area-bottom) + 24px);
`;

export const ContentStyled = styled(Content)`
  min-height: calc(100vh);
  padding: calc(64px + var(--safe-area-top)) 16px var(--safe-area-top);
`;

export const LayoutHome = ({
  header,
  content,
  footer,
}: {
  header: React.ReactNode;
  content: React.ReactNode;
  footer: React.ReactNode;
}) => {
  return (
    <>
      <HeaderHomeStyled>{header}</HeaderHomeStyled>
      {content}
      <FooterHomeStyled>{footer}</FooterHomeStyled>
    </>
  );
};

export const Layout = ({
  header,
  content,
  footer,
}: {
  header: React.ReactNode;
  content: React.ReactNode;
  footer?: React.ReactNode;
}) => {
  return (
    <>
      <HeaderStyled>{header}</HeaderStyled>
      {content}

      {footer && <FooterStyled>{footer}</FooterStyled>}
    </>
  );
};
