import { Dayjs } from "dayjs";
import { Repeat } from "../../types/repeat";
import { Days } from "../../types/days";

const dateValidation = (
  date: Dayjs,
  createAt: Dayjs,
  repeat: Repeat | null,
  endAt: Dayjs | null
): boolean => {
  if (date.isBefore(createAt, "day")) {
    return false;
  }

  if (!endAt || endAt.isSame(date, "day") || endAt.isAfter(date)) {
    if (!repeat || repeat.type === "weekly") {
      return true;
    }

    if (repeat.type === "daily") {
      const moment = repeat.moment as Days[];

      return moment.includes(date.format("dddd").toLowerCase() as Days);
    }

    if (repeat.type === "monthly") {
      const moment = repeat.moment as number[];

      return moment.includes(date.date());
    }
  }

  return false;
};

export default dateValidation;
