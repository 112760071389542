export enum NotificationTypes {
  DayPlan = "dayPlan",
  DaySummary = "daySummary",
  WeekPlan = "weekPlan",
  WeekSummary = "weekSummary",
  SetupComplete = "setupComplete",
}

export enum NotificationTypesValues {
  DayPlan = "Your daily plan is ready to kick off!",
  DaySummary = "Here is your daily summary!",
  WeekPlan = "Your weekly plan is ready to start!",
  WeekSummary = "Here is your weekly summary!",
  SetupComplete = "You will now receive notifications according to your schedule.",
}
