import { App, ConfigProvider, Flex, Typography } from "antd";

const { Title, Paragraph, Link, Text } = Typography;

export const PrivacyPage = () => {
  return (
    <ConfigProvider theme={{ cssVar: true }}>
      <App>
        <Flex vertical style={{ padding: "16px" }}>
          <Title level={2}>Privacy Policy</Title>
          <div>
            <Text strong>Privacy Policy for "Mom said" App</Text>
            <Paragraph>
              This Privacy Policy governs the manner in which "Mom said" app
              collects, uses, maintains, and discloses information collected
              from users of the application. This privacy policy applies to the
              app and all products and services offered by it.
            </Paragraph>
            <Text strong>Information Collection and Use:</Text>
            <Paragraph>
              The app does not collect any personal information as it operates
              offline, and all data is stored locally on the user's device. No
              data is transmitted to external servers or third-party services.
            </Paragraph>
            <Text strong>Analytics: </Text>
            <Paragraph>
              The app uses Vercel Analytics and Mixpanel to collect anonymized
              and aggregated data on user interactions within the app. This
              includes clicks and activities to improve user experience. None of
              this data personally identifies the user.
            </Paragraph>
            <Text strong>Security: </Text>
            <Paragraph>
              The app takes reasonable precautions to protect user data. All
              information is stored locally on the user's device and is not
              accessible by third parties.
            </Paragraph>
            <Text strong>Changes to This Privacy Policy:</Text>
            <Paragraph>
              The app developer reserves the right to update this privacy policy
              at any time. Users are encouraged to frequently check this page
              for any changes.
            </Paragraph>
            <Text strong> Your Acceptance of These Terms:</Text>
            <Paragraph>
              By using this app, you signify your acceptance of this policy. If
              you do not agree to this policy, please do not use the app.
            </Paragraph>
            <Text strong>Contacting Us:</Text>
            <Paragraph>
              If you have any questions about this Privacy Policy, the practices
              of this app, or your dealings with it, please contact us at{" "}
              <Link href={process.env.REACT_APP_SUPPORT_EMAIL}>
                {process.env.REACT_APP_SUPPORT_EMAIL}
              </Link>
            </Paragraph>
          </div>
        </Flex>
      </App>
    </ConfigProvider>
  );
};
