import { useEffect, useState } from "react";
import useNotificationStore from "../store/notification-store";

const useHydrationNotifications = () => {
  const [notificationHydrated, setNotificationHydrated] = useState(false);

  useEffect(() => {
    const unsubNotification = useNotificationStore.persist.onFinishHydration(
      () => setNotificationHydrated(true)
    );

    const unsubNotificationOnHydrate = useNotificationStore.persist.onHydrate(
      () => setNotificationHydrated(false)
    );

    setNotificationHydrated(useNotificationStore.persist.hasHydrated());

    return () => {
      unsubNotification();
      unsubNotificationOnHydrate();
    };
  }, [setNotificationHydrated]);

  return notificationHydrated;
};

export default useHydrationNotifications;
