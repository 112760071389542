import dayjs from "dayjs";

export const dateDeserialize = (key: string, value: unknown): unknown => {
  if (
    typeof value === "string" &&
    /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/.test(value)
  ) {
    return dayjs(value);
  }

  return value;
};
