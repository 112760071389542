import { useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";

export function useDays(): Dayjs[] {
  const [days, setDays] = useState<Dayjs[]>([]);
  const updateDays = () => {
    const currentDate = dayjs();
    const previousDays = [];
    const futureDays = [];

    for (let i = 7; i > 0; i--) {
      previousDays.push(currentDate.subtract(i, "day"));
    }

    for (let i = 1; i <= 30; i++) {
      futureDays.push(currentDate.add(i, "day"));
    }

    setDays([...previousDays, currentDate, ...futureDays]);
  };

  useEffect(() => {
    updateDays();
  }, []);

  return days;
}
