import {
  ClearOutlined,
  FileDoneOutlined,
  LeftOutlined,
} from "@ant-design/icons";
import {
  Layout as AntdLayout,
  Button,
  Empty,
  Flex,
  Space,
  Switch,
  Typography,
} from "antd";
import React, { ComponentProps } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  ContentStyled as ContentBaseStyled,
  Layout,
} from "../../core/components/layout/layout";
import NotificationCard from "../../core/components/notification-card/notification-card";
import useHydrationNotifications from "../../services/use-hydration-notifications";
import useNotificationStore from "../../store/notification-store";

const { Content } = AntdLayout;
const { Title, Text } = Typography;

const ContentStyled = styled(ContentBaseStyled)<
  { $notifications: any[] } & ComponentProps<typeof Content>
>`
  padding: calc(64px + var(--safe-area-top)) 16px
    calc(88px + var(--safe-area-bottom));
  display: flex;
  align-items: ${(props) =>
    props.$notifications.length ? "flex-start" : "center"};
  flex-direction: column;
  position: relative;
`;

const SpaceStyled = styled(Space)<
  { $notifications: any[] } & ComponentProps<typeof Space>
>`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-bottom: calc(88px + var(--safe-area-bottom));
  justify-content: ${(props) =>
    props.$notifications.length ? "flex-start" : "center"};
`;

const Notifications: React.FC = () => {
  const navigate = useNavigate();
  useHydrationNotifications();

  const showAllNotifications = useNotificationStore(
    (store) => store.showAllNotifications
  );

  const notifications = useNotificationStore((state) =>
    state.notifications
      .sort((a, b) => b.date.diff(a.date))
      .filter((item) => showAllNotifications || !item.isRead)
      .filter((item) => !item.deleted)
  );

  const { markAsRead, markAllAsRead, setShowAllNotifications, clearSoft } =
    useNotificationStore.getState();

  return (
    <Layout
      header={
        <>
          <Button
            icon={<LeftOutlined />}
            type="text"
            shape="circle"
            size="large"
            onClick={() => navigate("/")}
          />
          <Title
            level={3}
            style={{
              flex: 1,
              margin: 0,
              textAlign: "center",
              marginLeft: "-40px",
            }}
          >
            Notifications
          </Title>
        </>
      }
      content={
        <ContentStyled $notifications={notifications}>
          <Flex
            justify="space-between"
            align="center"
            style={{
              height: "34px",
              width: "100%",
              position: "sticky",
              top: 0,
              marginBottom: 12,
            }}
          >
            <Text style={{ margin: 0 }}>Show all</Text>
            <Switch
              value={showAllNotifications}
              onChange={setShowAllNotifications}
            />
          </Flex>

          <SpaceStyled
            direction="vertical"
            size="middle"
            $notifications={notifications}
          >
            {notifications.length ? (
              notifications.map((notification) => (
                <NotificationCard
                  key={notification.id}
                  title={notification.title}
                  subtitle={notification.body}
                  icon={notification.icon}
                  time={notification.date}
                  isNew={!notification.isRead}
                  onClick={() => markAsRead(notification.id)}
                />
              ))
            ) : (
              <Empty />
            )}
          </SpaceStyled>
        </ContentStyled>
      }
      footer={
        <>
          <Button
            style={{ width: "150px" }}
            type="text"
            icon={<ClearOutlined />}
            size="large"
            disabled={!notifications.length}
            onClick={() => clearSoft()}
          >
            Clear all
          </Button>

          <Button
            style={{ width: "150px" }}
            type="primary"
            icon={<FileDoneOutlined />}
            size="large"
            disabled={!notifications.filter((item) => !item.isRead).length}
            onClick={() => markAllAsRead()}
          >
            Mark as read
          </Button>
        </>
      }
    ></Layout>
  );
};

export default Notifications;
