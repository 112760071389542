import { Capacitor } from "@capacitor/core";
import { useEffect, useState } from "react";
import mixpanelService from "./mixpanel-service";

export function useInstalledStatus(): boolean {
  const [installed, setInstalled] = useState(false);

  useEffect(() => {
    function handleInstalled() {
      let displayMode = Capacitor.isNativePlatform()
        ? "standalone"
        : "browser tab";

      if (window.matchMedia("(display-mode: standalone)").matches) {
        displayMode = "standalone";
      }

      const hasInstallation = displayMode === "standalone";
      setInstalled(hasInstallation);
      mixpanelService.registerInstallation(hasInstallation);
    }

    handleInstalled();

    const appInstallHandler = () => {
      setInstalled(true);
      mixpanelService.trackAppInstalled();
    };

    window.addEventListener("appinstalled", appInstallHandler);

    return () => {
      window.removeEventListener("DOMContentLoaded", handleInstalled);
      window.removeEventListener("appinstalled", appInstallHandler);
    };
  }, []);

  return installed;
}
