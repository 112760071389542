import React from "react";
import { Button, Flex } from "antd";
import { Days } from "../../types/days";
import sortWeekdays from "../../core/utils/sort-weekdays";

type DaysProps = {
  startFrom: Days;
  selected: Days[];
  onChange: (value: Days[]) => void;
};

const Day: React.FC<DaysProps> = ({
  selected,
  onChange,
  startFrom,
}: DaysProps) => {
  const toggleState = (day: Days) => {
    if (selected.includes(day)) {
      onChange(selected.filter((item) => item !== day));
    } else {
      onChange([...selected, day]);
    }
  };

  const weekDaysOrdered = sortWeekdays(startFrom);

  return (
    <Flex align="center" gap="middle" justify="center">
      {weekDaysOrdered.map((day) => (
        <Button
          type={selected.includes(day.key) ? "primary" : "default"}
          shape="circle"
          size="middle"
          key={day.key}
          onClick={() => toggleState(day.key)}
        >
          {day.label}
        </Button>
      ))}
    </Flex>
  );
};

export default Day;
