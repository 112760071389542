import { Capacitor } from "@capacitor/core";
import {
  ActionPerformed,
  LocalNotificationSchema,
  LocalNotifications,
} from "@capacitor/local-notifications";
import axios from "axios";
import dayjs from "dayjs";
import {
  NotificationTypes,
  NotificationTypesValues,
} from "../core/consts/notification-types";
import {
  buildDailyPlanBody,
  buildDailySummaryBody,
} from "../core/formatters/notification-body";
import filterItems from "../core/utils/filter-items";
import todaysHistory from "../core/utils/today-history-filter";
import useHabitStore from "../store/habit-store";
import useNotificationStore from "../store/notification-store";
import mixpanelService from "./mixpanel-service";

class NotificationServiceWeb {
  private readonly apiUrl = process.env.REACT_APP_API_URL;
  private readonly headers = {
    Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
    Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };

  private urlBase64ToUint8Array(base64String: string): Uint8Array {
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding)
      .replace(/-/g, "+")
      .replace(/_/g, "/");

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }

  private async saveSubscription(
    subscription: PushSubscription
  ): Promise<void> {
    await axios.post(`${this.apiUrl}/subscription`, subscription, {
      headers: this.headers,
    });
  }

  async hasPermissions(): Promise<boolean> {
    if (!("Notification" in window)) {
      console.warn("This browser does not support notifications");
      return false;
    }

    return Notification.permission === "granted";
  }

  async requestNotificationSubscription(): Promise<boolean> {
    if (!("serviceWorker" in navigator)) {
      console.warn("This browser does not support service workers");
      return false;
    }

    try {
      const { pushManager } = await navigator.serviceWorker.ready;
      let subscription = await pushManager.getSubscription();

      if (!subscription) {
        console.log("Requesting subscription");

        subscription = await pushManager
          .subscribe({
            userVisibleOnly: true,
            applicationServerKey: this.urlBase64ToUint8Array(
              process.env.REACT_APP_PUSH_PUBLIC_KEY
            ),
          })
          .then((subscription: PushSubscription) => {
            mixpanelService.trackAcceptNotifications();

            return subscription;
          })
          .catch((e) => {
            mixpanelService.trackDeclineNotifications();

            throw e;
          });
      }

      // todo: add catching exeption for unconsistent subscription
      await this.saveSubscription(subscription);
      return true;
    } catch (e) {
      console.warn("Subscription failed", e);
      return false;
    }
  }

  handleNotificationRecieved(): void {
    useNotificationStore.persist.rehydrate();
  }

  async handleScheduleChange(
    vacationOn: boolean,
    hasDailyPlan: boolean,
    hasDailySummary: boolean
  ): Promise<void> {
    return;
  }

  async handleNotificationCheck(): Promise<void> {
    await this.requestNotificationSubscription();
  }
}

class NotificationServiceNative {
  private readonly dailyPlanNotification = {
    id: this.generateId(),
    title: "Plan of the Day",
    body: "Your daily plan is ready to kick off!",
    schedule: {
      allowWhileIdle: true,
      on: {
        second: 0,
        minute: 30,
        hour: 8,
      },
    },
    extra: {
      type: NotificationTypes.DayPlan,
    },
  };

  private readonly dailySummaryNotification = {
    id: this.generateId(),
    title: "Day Summary",
    body: "Here is your daily summary!",
    schedule: {
      allowWhileIdle: true,
      on: {
        second: 0,
        minute: 0,
        hour: 21,
      },
    },
    extra: {
      type: NotificationTypes.DaySummary,
    },
  };

  private readonly notificationChange = {
    id: this.generateId(),
    title: "Setup Complete!",
    body: "",
    schedule: {
      allowWhileIdle: true,
      get at() {
        return dayjs().add(1, "second").toDate();
      },
    },
    extra: {
      type: NotificationTypes.SetupComplete,
    },
  };

  private getNotificationChangeBody = (
    vacationOn: boolean,
    hasDailyPlan: boolean,
    hasDailySummary: boolean
  ) => {
    if (vacationOn) {
      return "Your notifications are disabled.";
    }

    if (hasDailyPlan && !hasDailySummary) {
      return "You will now receive daily plan notifications.";
    }

    if (hasDailySummary && !hasDailyPlan) {
      return "You will now receive daily summary notifications.";
    }

    return "You will now receive notifications according to your schedule.";
  };

  private generateId(): number {
    // JavaScript's Number.MAX_SAFE_INTEGER is 9007199254740991
    // For an int range similar to Java's, you might use:
    const min = -2147483648; // Java int min value
    const max = 2147483647; // Java int max value
    // Generate a random int between min and max
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  private createNotificationItemInStore = (
    notification: LocalNotificationSchema
  ): void => {
    const { getState: getNotificationStore } = useNotificationStore;
    const { getState: getHabitStore } = useHabitStore;
    const { addNotification } = getNotificationStore();
    const { allItems, history } = getHabitStore();
    const todaysItems = filterItems(allItems, dayjs());

    if (notification.extra.type === NotificationTypes.DayPlan) {
      addNotification({
        title: NotificationTypesValues.DayPlan,
        body: buildDailyPlanBody(todaysItems),
        icon: "🚀",
      });
    }

    if (notification.extra.type === NotificationTypes.DaySummary) {
      addNotification({
        title: NotificationTypesValues.DaySummary,
        body: buildDailySummaryBody(
          todaysItems,
          todaysHistory(todaysItems, history)
        ),
        icon: "🚢",
      });
    }
  };

  async hasPermissions(): Promise<boolean> {
    const permissions = await LocalNotifications.checkPermissions();

    return permissions.display === "granted";
  }

  async requestNotificationSubscription(): Promise<boolean> {
    try {
      await LocalNotifications.requestPermissions();
    } catch (e) {
      console.log(
        "Error occurred while requesting notification subscription:",
        e
      );

      return false;
    }

    return true;
  }

  handleNotificationRecieved(): void {}

  async handleScheduleChange(
    vacationOn: boolean,
    hasDailyPlan: boolean,
    hasDailySummary: boolean
  ): Promise<void> {
    const notification = {
      ...this.notificationChange,
      body: this.getNotificationChangeBody(
        vacationOn,
        hasDailyPlan,
        hasDailySummary
      ),
    };

    const pendingList = await LocalNotifications.getPending();

    const hasPendingDailyPlan = pendingList.notifications.filter(
      (item) => item.extra.type === NotificationTypes.DayPlan
    );

    const hasPendingDailySummary = pendingList.notifications.filter(
      (item) => item.extra.type === NotificationTypes.DaySummary
    );

    if (hasPendingDailyPlan.length) {
      await LocalNotifications.cancel({
        notifications: [...hasPendingDailyPlan],
      });
    }

    if (hasPendingDailySummary.length) {
      await LocalNotifications.cancel({
        notifications: [...hasPendingDailySummary],
      });
    }

    await LocalNotifications.removeAllListeners();
    await LocalNotifications.addListener(
      "localNotificationActionPerformed",
      (action: ActionPerformed) => {
        // todo: tracking to validate to demand
        this.createNotificationItemInStore(action.notification);
      }
    );

    if (vacationOn && pendingList.notifications.length) {
      await LocalNotifications.cancel({
        notifications: [...pendingList.notifications],
      });

      await LocalNotifications.schedule({
        notifications: [notification],
      });

      return;
    }

    if (hasDailyPlan) {
      await LocalNotifications.schedule({
        notifications: [this.dailyPlanNotification],
      });
    }

    if (hasDailySummary) {
      await LocalNotifications.schedule({
        notifications: [this.dailySummaryNotification],
      });
    }

    await LocalNotifications.schedule({
      notifications: [notification],
    });
  }

  async handleNotificationCheck(): Promise<void> {
    // const deliveredNotifications =
    //   await LocalNotifications.getDeliveredNotifications();
    // deliveredNotifications.notifications.forEach((notification) => {
    //   this.createNotificationItemInStore(notification);
    // });
  }
}

export default Capacitor.isNativePlatform()
  ? new NotificationServiceNative()
  : new NotificationServiceWeb();
