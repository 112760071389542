import dayjs from "dayjs";
import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import { dateDeserialize } from "../core/utils/date-deserializer";
import { Days } from "../types/days";
import storage from "./async-storage";
import { SettingsStore } from "./types";

interface SettingsActions {
  setWeekStartDay: (day: Days) => void;
  setVacationMode: (mode: boolean) => void;
  setSounds: (sounds: boolean) => void;
  setPlanOfDayTime: (time: number | null) => void;
  setPlanOfWeekTime: (time: number | null) => void;
  setDaySummaryTime: (time: number | null) => void;
  setWeekSummaryTime: (time: number | null) => void;
  setNotificationPermission: (permission: boolean) => void;
  setShowCompleted: (showCompleted: boolean) => void;
  setInstallAppModalOpened: (installAppModalOpened: boolean) => void;
  bulkUpdate: (settings: SettingsStore) => void;
}

const useSettingsStore = create<SettingsStore & SettingsActions>()(
  devtools(
    persist(
      (set) => ({
        weekStartDay: "monday",
        vacationStartAt: null,
        sounds: true,
        planOfDayTime: null,
        planOfWeekTime: null,
        daySummaryTime: null,
        weekSummaryTime: null,
        notificationPermission:
          "Notification" in window
            ? Notification.permission !== "denied"
            : false,
        vacationHistory: [],
        showCompleted: false,
        installAppModalOpened: true,
        setWeekStartDay: (day: Days): void => set({ weekStartDay: day }),
        setVacationMode: (mode: boolean): void => {
          if (mode) {
            set((state) => ({
              vacationHistory: [...state.vacationHistory, [dayjs(), null]],
            }));
          } else {
            set((state) => {
              const lastVacation =
                state.vacationHistory.find(([, endAt]) => !endAt) ?? null;

              if (lastVacation) {
                const [startAt] = lastVacation;

                return {
                  vacationHistory: [
                    ...state.vacationHistory.filter(([, endAt]) => !!endAt),
                    [startAt, dayjs()],
                  ],
                };
              }

              return state;
            });
          }
        },
        setNotificationPermission: (permission: boolean): void =>
          set({ notificationPermission: permission }),
        setSounds: (sounds: boolean): void => set({ sounds }),
        setPlanOfDayTime: (time: number | null): void =>
          set({ planOfDayTime: time }),
        setPlanOfWeekTime: (time: number | null): void =>
          set({ planOfWeekTime: time }),
        setDaySummaryTime: (time: number | null): void =>
          set({ daySummaryTime: time }),
        setWeekSummaryTime: (time: number | null): void =>
          set({ weekSummaryTime: time }),
        setShowCompleted: (showCompleted: boolean): void =>
          set({ showCompleted }),

        setInstallAppModalOpened: (installAppModalOpened: boolean): void =>
          set({ installAppModalOpened }),

        bulkUpdate: (settings: SettingsStore): void =>
          set({
            ...settings,
            installAppModalOpened: false,
          }),
      }),
      {
        name: "settings-store",
        storage: createJSONStorage(() => storage, { reviver: dateDeserialize }),
      }
    ),
    {
      name: "settings-store",
    }
  )
);

export default useSettingsStore;
