import { Card, Flex, Typography } from "antd";
import { Dayjs } from "dayjs";
import React from "react";

const { Text, Paragraph } = Typography;

interface HabitCardProps {
  icon: string;
  title: string;
  subtitle: string;
  navigate: () => void;
  createdAt: Dayjs;
  completedAt: Dayjs | null;
}

const HabitCard: React.FC<HabitCardProps> = ({
  icon,
  title,
  subtitle,
  navigate,
  createdAt,
  completedAt,
}) => {
  return (
    <Card
      onClick={navigate}
      style={{ width: "100%" }}
      bordered={false}
      size="small"
      title={
        <Flex align="center" justify="space-between">
          <Text delete={!!completedAt} ellipsis>
            {title}
          </Text>
          {completedAt ? (
            <Text
              type="secondary"
              style={{
                fontSize: "12px",
              }}
            >
              Completed at {completedAt.format("DD MMM YYYY")}
            </Text>
          ) : (
            <Text
              type="secondary"
              style={{
                fontSize: "12px",
              }}
            >
              Created at {createdAt.format("DD MMM YYYY")}
            </Text>
          )}
        </Flex>
      }
    >
      <Flex gap="small" align="center">
        {React.cloneElement(<div>{icon}</div>, {
          style: {
            fontSize: "30px",
            height: "100%",
            alignItems: "center",
            lineHeight: "30px",
            overflow: "hidden",
            minWidth: "35px",
          },
        })}

        <Paragraph
          type="secondary"
          ellipsis={{ rows: 2 }}
          style={{ marginBottom: 0, lineHeight: "16px" }}
        >
          {subtitle}
        </Paragraph>
      </Flex>
    </Card>
  );
};

export default HabitCard;
