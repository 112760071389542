import NotificationService from "./services/notification-service";

export function registerServiceWorker(): void {
  if ("serviceWorker" in navigator) {
    window.addEventListener("load", () => {
      navigator.serviceWorker
        .register("/sw.js")
        .then(async (registration) => {
          console.info(
            "Service Worker registered with scope:",
            registration.scope
          );

          // when we have granted permission, we already have saved subscription in API side.
          // so the subscruption success notification will be appeared only for re-creation
          // safari drops subs if there is no notification after push from api
          if (await NotificationService.hasPermissions()) {
            NotificationService.handleNotificationCheck();
          }
        })
        .catch((error) => {
          console.error("Service Worker registration failed:", error);
        });
    });
  }
}
