import dayjs, { Dayjs } from "dayjs";

const getFormattedString = (date: Dayjs) => {
  if (date.isToday()) {
    return "Today";
  }

  if (date.isSame(dayjs().subtract(1, "day"), "day")) {
    return "Yesterday";
  }

  if (date.isSame(dayjs().add(1, "day"), "day")) {
    return "Tomorrow";
  }

  return date.format("MMMM D");
};

export default getFormattedString;
