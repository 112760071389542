import dayjs, { Dayjs } from "dayjs";
import { nanoid } from "nanoid";
import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import { dateDeserialize } from "../core/utils/date-deserializer";
import filterItems from "../core/utils/filter-items";
import { Habit } from "../types/habit";
import { History } from "../types/history";
import storage from "./async-storage";
import { HabitStore } from "./types";

interface HabitActions {
  setDay: (date: Dayjs) => void;
  addItem: (by: Omit<Habit, "id" | "createdAt">) => void;
  getItem: (id: string) => Habit | null;
  getFilteredItems: () => Habit[];
  updateItem: (id: string, item: Partial<Habit>) => void;
  increaseHistory: (id: string, step: number) => void;
  markAsDone: (id: string) => void;
  reset: (id: string) => void;
  clear: () => void;
  deleteItem: (id: string) => void;
  bulkAdd: (items: Habit[], history: History) => void;
  setAllowDesktop: () => void;
}

const useHabitStore = create<HabitStore & HabitActions>()(
  devtools(
    persist(
      (set, get): HabitStore & HabitActions => {
        return {
          allowDesktop: false,
          currentDay: dayjs(),
          allItems: [],
          history: {},
          setDay: (date: Dayjs): void => set({ currentDay: date }),
          getItem: (id: string): Habit | null =>
            get().allItems.find((item) => item.id === id) ?? null,
          addItem: (item: Omit<Habit, "id" | "createdAt">) =>
            set((state) => {
              const newItem: Habit = {
                ...item,
                id: nanoid(),
                createdAt: dayjs(),
              };

              return {
                allItems: [...state.allItems, newItem],
              };
            }),
          getFilteredItems: (): Habit[] => {
            const { currentDay, allItems, history } = get();

            return filterItems(allItems, currentDay)
              .filter((item: Habit) => {
                const itemHistory = history[item.id] ?? [];

                if (!item.repeat) {
                  const itemHistoryRecord = itemHistory.find(
                    (record) => record.done
                  );

                  return itemHistoryRecord
                    ? itemHistoryRecord?.date.isSame(currentDay, "day")
                    : true;
                }

                return true;
              })
              .sort((a, b) => {
                const aHistory = get().history[a.id];
                const bHistory = get().history[b.id];

                const aRecord = aHistory?.find((record) =>
                  record.date.isSame(currentDay, "day")
                );
                const bRecord = bHistory?.find((record) =>
                  record.date.isSame(currentDay, "day")
                );

                if (aRecord?.done && !bRecord?.done) {
                  return 1;
                }

                if (!aRecord?.done && bRecord?.done) {
                  return -1;
                }

                return 0;
              });
          },
          updateItem: (id: string, item: Partial<Habit>) => {
            set((state) => ({
              allItems: state.allItems.map((habit) =>
                habit.id === id ? Object.assign(habit, item) : habit
              ),
            }));
          },
          increaseHistory: (id: string, step: number) =>
            set((state: HabitStore): Partial<HabitStore> => {
              const history = state.history[id];

              const item = state.allItems.find((item) => item.id === id);
              const goal = item?.goal?.unit.count ?? 0;

              if (!history) {
                return {
                  history: {
                    ...state.history,
                    [id]: [
                      {
                        date: state.currentDay,
                        count: step,
                        done: step >= goal,
                      },
                    ],
                  },
                };
              }

              const record = history.find((record) =>
                record.date.isSame(state.currentDay, "day")
              );

              if (!record) {
                return {
                  history: {
                    ...state.history,
                    [id]: [
                      ...history,
                      {
                        date: state.currentDay,
                        count: step,
                        done: step >= goal,
                      },
                    ],
                  },
                };
              }

              return {
                history: {
                  ...state.history,
                  [id]: history.map((record) =>
                    record.date.isSame(state.currentDay, "day")
                      ? {
                          ...record,
                          count: record.count + step,
                          done: record.count + step >= goal,
                        }
                      : record
                  ),
                },
              };
            }),
          markAsDone: (id: string) => {
            set((state: HabitStore): Partial<HabitStore> => {
              const history = state.history[id];

              const item = state.allItems.find((item) => item.id === id);
              const goal = item?.goal?.unit.count ?? 0;

              if (!history) {
                return {
                  history: {
                    ...state.history,
                    [id]: [{ date: state.currentDay, done: true, count: goal }],
                  },
                };
              }

              const record = history.find((record) =>
                record.date.isSame(state.currentDay, "day")
              );

              if (!record) {
                return {
                  history: {
                    ...state.history,
                    [id]: [
                      ...history,
                      { date: state.currentDay, count: goal, done: true },
                    ],
                  },
                };
              }

              return {
                history: {
                  ...state.history,
                  [id]: history.map((record) =>
                    record.date.isSame(state.currentDay, "day")
                      ? {
                          ...record,
                          count: goal,
                          done: true,
                        }
                      : record
                  ),
                },
              };
            });
          },
          reset: (id: string) => {
            set((state: HabitStore): Partial<HabitStore> => {
              return {
                history: {
                  ...state.history,
                  [id]: [],
                },
              };
            });
          },

          clear: () => set({ allItems: [], history: {} }),
          deleteItem: (id: string) =>
            set((state: HabitStore): Partial<HabitStore> => {
              const { [id]: _, ...history } = state.history;

              return {
                allItems: state.allItems.filter((item) => item.id !== id),
                history,
              };
            }),
          bulkAdd: (items: Habit[], history: History) =>
            set({
              allItems: items,
              history,
            }),

          setAllowDesktop: () => set({ allowDesktop: true }),
        };
      },
      {
        name: "habit-store",
        storage: createJSONStorage(() => storage, { reviver: dateDeserialize }),
      }
    ),
    { name: "habit-store" }
  )
);

export default useHabitStore;
