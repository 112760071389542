import { Button, Flex, QRCode, Typography } from "antd";
import React from "react";
import styled from "styled-components";

const { Title } = Typography;

const MobileExperienceContainer = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MobileExperience: React.FC<{ onContinue: () => void }> = ({
  onContinue,
}) => {
  return (
    <MobileExperienceContainer>
      <Flex vertical align="center" gap="middle">
        <Title level={5}>Use mobile phone for best experience</Title>
        <QRCode
          errorLevel="H"
          value={`https://${process.env.REACT_APP_DOMAIN}`}
          icon="/logo.png"
        />

        <Button type="text" onClick={onContinue}>
          Continue on desktop
        </Button>
      </Flex>
    </MobileExperienceContainer>
  );
};
