import React from "react";
import { Button, Flex } from "antd";

type WeekProps = {
  selected: number | null;
  onChange: (value: number | null) => void;
};

const Week: React.FC<WeekProps> = ({ selected, onChange }) => {
  const toggleState = (day: number) => {
    if (selected === day) {
      onChange(null);
    } else {
      onChange(day);
    }
  };

  return (
    <Flex align="center" gap="middle" justify="center">
      {new Array(6).fill(null).map((_, index) => {
        const day = index + 1;
        return (
          <Button
            type={selected === day ? "primary" : "default"}
            shape="circle"
            size="middle"
            key={index}
            onClick={() => toggleState(day)}
          >
            {day}
          </Button>
        );
      })}
    </Flex>
  );
};

export default Week;
