import { Capacitor } from "@capacitor/core";
import "antd/dist/reset.css";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import isToday from "dayjs/plugin/isToday";
import relativeTime from "dayjs/plugin/relativeTime";
import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import itemLoader from "./core/loaders/item-loader";
import lockOrientation from "./core/utils/lock-orientation";
import EditItem from "./pages/edit-item/edit-item";
import ErrorBoundary from "./pages/errors/error-boundary";
import NotFound from "./pages/errors/not-found";
import Home from "./pages/home/home";
import ItemList from "./pages/item-list/item-list";
import NewItem from "./pages/new-item/new-item";
import Notifications from "./pages/notifications/notifications";
import { PrivacyPage } from "./pages/privacy/privacy";
import Root from "./pages/root";
import Settings from "./pages/settings/settings";
import ViewItem from "./pages/view-item/view-item";
import { registerServiceWorker } from "./service-worker-register";
import eventBusService from "./services/event-bus-service";
import mixpanelService from "./services/mixpanel-service";
import notificationService from "./services/notification-service";
import injectSentry from "./services/sentry-service";
import safeAreaProperies from "./utils/safe-area";

let SpeedInsights = null;

if (process.env.NODE_ENV === "production") {
  lockOrientation();
  injectSentry();
  mixpanelService.inject();

  import("@vercel/analytics").then(({ inject }) => inject());
  import("@vercel/speed-insights/react").then(
    ({ SpeedInsights: Component }) => {
      SpeedInsights = Component;
    }
  );

  console.info("version", process.env.REACT_APP_HASH || "dev");
}

eventBusService.connect().listen("notifications:new", (payload?: unknown) => {
  notificationService.handleNotificationRecieved();
});

dayjs.extend(isToday);
dayjs.extend(duration);
dayjs.extend(relativeTime);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        index: true,
        element: <Home />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "new",
        element: <NewItem />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "items",
        element: <ItemList />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "items/:id",
        element: <ViewItem />,
        loader: itemLoader,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "items/:id/edit",
        element: <EditItem />,
        loader: itemLoader,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "settings",
        element: <Settings />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "notifications",
        element: <Notifications />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },

  {
    path: "privacy",
    element: <PrivacyPage />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
    {SpeedInsights}
  </React.StrictMode>
);

if (Capacitor.isNativePlatform()) {
  safeAreaProperies();
  // notificationService.handleNotificationCheck();
} else {
  registerServiceWorker();
}
