import {
  CloseOutlined,
  EditOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import { Button, Col, Flex, FloatButton, Row, Typography, message } from "antd";
import dayjs, { Dayjs } from "dayjs";
import React from "react";
import Lottie, { Options } from "react-lottie";
import { useLoaderData, useNavigate } from "react-router-dom";
import doneAnimationData from "../../core/assets/lottie/great-job.json";
import yogaAnimationData from "../../core/assets/lottie/yoga.json";
import { ContentStyled, Layout } from "../../core/components/layout/layout";
import { animationOptions } from "../../core/consts/animation-options";
import * as formatters from "../../core/formatters";
import { getUnitsFormatted } from "../../core/formatters";
import dateValidation from "../../core/utils/date-validation";
import sortWeekdays from "../../core/utils/sort-weekdays";
import mixpanelService from "../../services/mixpanel-service";
import useHabitStore from "../../store/habit-store";
import useSettingsStore from "../../store/settings-store";
import { Days } from "../../types/days";
import { Habit } from "../../types/habit";
import { HistoryRecord } from "../../types/history-record";
import { RepeatType } from "../../types/repeat-type";

const { Title, Text, Paragraph } = Typography;

const greatJobAnimationOptions: Options = {
  ...animationOptions,
  loop: true,
  animationData: doneAnimationData,
};

const yogaAnimationOptions: Options = {
  ...animationOptions,
  loop: true,
  animationData: yogaAnimationData,
};

const ViewItem: React.FC = () => {
  const [messageApi, contextHolder] = message.useMessage();

  const navigate = useNavigate();
  const fullHistory = useHabitStore((state) => state.history);
  const vacationHistory = useSettingsStore((state) => state.vacationHistory);
  const { item } = useLoaderData() as { item: Habit };
  const weekStartDay = useSettingsStore((state) => state.weekStartDay);

  const getCurrentStreak = (missed = false): number => {
    const history = (fullHistory[item.id] ?? []).filter(
      (item: HistoryRecord) => item.done
    );

    const today = dayjs();
    const {createdAt, repeat, endDate} = item;

    const missedDates: Dayjs[] = [];
    const completedDates: Dayjs[] = [];

    for (let i = createdAt.clone(); i.isBefore(today); i = i.add(1, "day")) {
      if (
        dateValidation(i, createdAt, repeat, endDate) &&
        !vacationHistory.some(([start, end]) => {
          end = end ?? today;
          return (
            start.add(-1, "day").isBefore(i, "day") &&
            end.add(1, "day").isAfter(i, "day")
          );
        })
      ) {
        const historyItem = history.find((h) => h.date.isSame(i, "day"));

        if (historyItem) {
          completedDates.push(i);
        } else if (!i.isSame(today, "day")) {
          missedDates.push(i);
        }
      }
    }

    return missed ? missedDates.length : completedDates.length;
  };

  const share = () => {
    mixpanelService.trackViewItemShare();

    const text = item.repeat
      ? `I've been doing "${item.title.trim()}" for ${getCurrentStreak()} days!\nCheck out app: ${
          process.env.REACT_APP_NAME
        }\nhttps://${process.env.REACT_APP_DOMAIN}`
      : `I'm working on ${item.title.trim()}!\nCheck out app: ${
          process.env.REACT_APP_NAME
        }\nhttps://${process.env.REACT_APP_DOMAIN}`;

    if (!navigator.share) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          return messageApi.open({
            type: "success",
            content: "Copied to clipboard!",
            style: {
              marginTop: "var(--safe-area-top)",
            },
          });
        })
        .catch(() => {
          return messageApi.open({
            type: "warning",
            content: "Could not copy to clipboard!",
            style: {
              marginTop: "var(--safe-area-top)",
            },
          });
        });

      return;
    }

    navigator
      .share({
        title: `Look at my "${item.title.trim()}" streak!`,
        text,
      })
      .catch();
  };

  const getPeriodFormatted = (
    period: RepeatType,
    moment: Days[] | number[] | number | null
  ) => {
    switch (period) {
      case "daily": {
        moment = sortWeekdays(weekStartDay)
          .map((day) => day.key)
          .filter((day) => (moment as Days[]).includes(day));
        break;
      }

      case "weekly": {
        moment = moment as number | null;
        break;
      }

      case "monthly": {
        moment = moment as number[];
        break;
      }
    }

    return formatters.getPeriodFormatted(period, moment);
  };

  return (
    <Layout
      header={
        <Flex justify="space-between" flex={1}>
          <Button
            type="text"
            shape="circle"
            size="large"
            icon={<CloseOutlined />}
            onClick={() => {
              navigate(-1);
              mixpanelService.trackViewItemClickClose();
            }}
          />

          <Button
            icon={<EditOutlined />}
            type="text"
            shape="circle"
            size="large"
            onClick={() => {
              navigate(`/items/${item.id}/edit`);
              mixpanelService.trackViewItemClickEdit();
            }}
          />
        </Flex>
      }
      content={
        <ContentStyled>
          <Flex vertical gap="large">
            <Flex vertical align="center">
              {React.cloneElement(<span>{item.icon}</span>, {
                style: {
                  fontSize: "46px",
                  lineHeight: "46px",
                  alignItems: "center",
                  minWidth: "46px",
                  overflow: "hidden",
                },
              })}
            </Flex>

            <Flex vertical gap="small" style={{ margin: "0 16px" }}>
              <Title
                level={3}
                style={{ flex: 1, margin: 0, textAlign: "center" }}
                ellipsis={{ rows: 1 }}
              >
                {item.title}
              </Title>
              {item.description ? (
                <Paragraph
                  style={{
                    flex: 1,
                    margin: 0,
                    textAlign: "center",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  {item.description}
                </Paragraph>
              ) : (
                <Text type="secondary" style={{ textAlign: "center" }}>
                  No description (just do it)
                </Text>
              )}
            </Flex>

            <Flex gap="small" vertical style={{ margin: "0 16px 32px" }}>
              {item.goal && (item.goal?.unit?.count ?? 0) > 1 ? (
                <Row gutter={[16, 16]}>
                  <Col span={6}>
                    <Text strong style={{ flexBasis: "30%" }}>
                      Goal
                    </Text>
                  </Col>
                  <Col span={18}>
                    <Text
                      ellipsis={{
                        tooltip: getUnitsFormatted(
                          item.goal.unit.count,
                          item.goal.unit.type
                        ),
                      }}
                    >
                      {getUnitsFormatted(
                        item.goal.unit.count,
                        item.goal.unit.type
                      )}
                    </Text>
                  </Col>
                </Row>
              ) : null}
              {item.repeat ? (
                <Row gutter={[16, 16]}>
                  <Col span={6}>
                    <Text strong style={{ flexBasis: "30%" }}>
                      Repeat
                    </Text>
                  </Col>
                  <Col span={18}>
                    <Text
                      ellipsis={{
                        tooltip: getPeriodFormatted(
                          item.repeat.type,
                          item.repeat.moment
                        ),
                      }}
                    >
                      {getPeriodFormatted(item.repeat.type, item.repeat.moment)}
                    </Text>
                  </Col>
                </Row>
              ) : null}
              {item.endDate ? (
                <Row gutter={[16, 16]}>
                  <Col span={6}>
                    <Text strong style={{ flexBasis: "30%" }}>
                      Until
                    </Text>
                  </Col>
                  <Col span={18}>
                    <Text>{item.endDate.format("DD MMM YYYY")}</Text>
                  </Col>
                </Row>
              ) : null}
            </Flex>

            <Flex gap="small" vertical align="center">
              {item.repeat ? (
                <>
                  <Text strong style={{ marginBottom: "16px" }}>
                    Current streak
                  </Text>

                  <Flex
                    gap="small"
                    vertical
                    align="center"
                    style={{ marginBottom: "16px" }}
                  >
                    <Text>You completed</Text>
                    <Text strong>{getCurrentStreak()} days</Text>
                  </Flex>

                  <Flex gap="small" vertical align="center">
                    <Text>You missed</Text>
                    <Text strong>{getCurrentStreak(true)} days</Text>
                  </Flex>

                  <Lottie
                    options={greatJobAnimationOptions}
                    width={250}
                    isClickToPauseDisabled={true}
                  />
                </>
              ) : (
                <Lottie
                  options={yogaAnimationOptions}
                  width={250}
                  isClickToPauseDisabled={true}
                />
              )}
            </Flex>

            {contextHolder}
            <FloatButton
              shape="circle"
              icon={<ShareAltOutlined />}
              onClick={() => share()}
            />
          </Flex>
        </ContentStyled>
      }
    ></Layout>
  );
};

export default ViewItem;
