import { Capacitor } from "@capacitor/core";
import { Drivers, Storage } from "@ionic/storage";
import localforage from "localforage";
import CordovaSQLiteDriver from "localforage-cordovasqlitedriver";
import { StateStorage } from "zustand/middleware";

class WebStorage implements StateStorage {
  constructor() {
    navigator.storage.persist().finally();
    localforage.config({
      driver: localforage.INDEXEDDB,
      name: process.env.REACT_APP_STORE_GROUP,
      version: 1.0,
      storeName: process.env.REACT_APP_STORE_NAME,
    });
  }

  getItem = async (name: string): Promise<string | null> => {
    const state = await localforage.getItem<string>(name);

    return state ? state : null;
  };

  setItem = async (name: string, value: string): Promise<void> => {
    await localforage.setItem(name, value);
  };

  removeItem = async (name: string): Promise<void> => {
    await localforage.removeItem(name);
  };
}

class NativeStorage implements StateStorage {
  private defer$: Promise<Storage> | null = null;
  private store: Storage | null = null;

  constructor() {
    this.store = new Storage({
      name: process.env.REACT_APP_STORE_GROUP,
      storeName: process.env.REACT_APP_STORE_NAME,
      version: 1.0,
      driverOrder: [
        CordovaSQLiteDriver._driver,
        Drivers.IndexedDB,
        Drivers.LocalStorage,
      ],
    });
    this.defer$ = this.store.create();
  }

  getItem = async (name: string): Promise<string | null> => {
    await this.defer$;
    const state = await this.store?.get(name);

    return state ? state : null;
  };

  setItem = async (name: string, value: string): Promise<void> => {
    await this.defer$;
    await this.store?.set(name, value);
  };

  removeItem = async (name: string): Promise<void> => {
    await this.defer$;
    await this.store?.remove(name);
  };
}

export default Capacitor.isNativePlatform()
  ? new NativeStorage()
  : new WebStorage();
