import { Segmented } from "antd";
import styled from "styled-components";

const HomeSegmentedStyles = styled(Segmented)`
  position: fixed;
  z-index: 1;
  top: calc(128px + var(--safe-area-top));
  width: 100%;
  padding: 0 16px;
  background: transparent;
  padding-bottom: 16px;
  max-width: var(--max-width);
`;

export const HomeSegmented = HomeSegmentedStyles;
