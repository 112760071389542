import { Days } from "../../types/days";

export const weekDays: { key: Days; label: string }[] = [
  {
    key: "monday",
    label: "M",
  },
  {
    key: "tuesday",
    label: "T",
  },
  {
    key: "wednesday",
    label: "W",
  },
  {
    key: "thursday",
    label: "T",
  },
  {
    key: "friday",
    label: "F",
  },
  {
    key: "saturday",
    label: "S",
  },
  {
    key: "sunday",
    label: "S",
  },
];
