import { Capacitor } from "@capacitor/core";
import { App, ConfigProvider, Flex, Layout, Spin } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { isDesktop } from "react-device-detect";
import { Outlet } from "react-router-dom";
import { MobileExperience } from "../core/components/splash-screen/mobile-experience";
import SplashScreen from "../core/components/splash-screen/splash-screen";
import useHydration from "../services/use-hydration-once";
import useHabitStore from "../store/habit-store";

const Root: React.FC = () => {
  const [showSplashScreen, setShowSplashScreen] = useState<boolean>(true);
  const [showMobileQr, setShowMobileQr] = useState<boolean>(isDesktop);

  const isReady = useHydration();
  const currentDay = useHabitStore((state) => state.currentDay);
  const allowDesktop = useHabitStore((state) => state.allowDesktop);
  const { setDay, setAllowDesktop } = useHabitStore.getState();

  useEffect(() => {
    if (allowDesktop) {
      setShowMobileQr(false);
    }
  }, [allowDesktop]);

  useEffect(() => {
    if (!currentDay.isToday()) {
      setDay(dayjs());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady]);

  const webLayout = (
    <>
      {showSplashScreen && (
        <SplashScreen animationDone={() => setShowSplashScreen(false)} />
      )}

      {showMobileQr && !showSplashScreen && (
        <MobileExperience onContinue={() => setAllowDesktop()} />
      )}

      {!showMobileQr && !showSplashScreen && isReady && (
        <Layout>
          <Outlet />
        </Layout>
      )}

      {!showMobileQr && !showSplashScreen && !isReady && (
        <Flex
          vertical
          align="center"
          justify="center"
          style={{ height: "100vh" }}
        >
          <Spin size="large" />
        </Flex>
      )}
    </>
  );

  const nativeLayout = (
    <Layout>
      <Outlet />
    </Layout>
  );

  const isNative = Capacitor.isNativePlatform();

  return (
    <ConfigProvider theme={{ cssVar: true }}>
      <App>{isNative ? nativeLayout : webLayout}</App>
    </ConfigProvider>
  );
};

export default Root;
