export const lockOrientation = (): void => {
  if (window.screen.orientation?.lock) {
    window.screen.orientation.lock?.("portrait").catch((error) => {
      console.warn("Failed to lock orientation:", error);
    });
  } else {
    console.warn("Screen orientation API not supported.");
  }
};

function inject(): void {
  lockOrientation();
  window.addEventListener("orientationchange", lockOrientation);
}

export default inject;
