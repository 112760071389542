import dayjs from "dayjs";
import { nanoid } from "nanoid";
import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import { dateDeserialize } from "../core/utils/date-deserializer";
import { Notification } from "../types/notification";
import storage from "./async-storage";
import { NotificationStore } from "./types";

interface NotificationActions {
  addNotification: (
    notification: Omit<Notification, "isRead" | "id" | "deleted" | "date">
  ) => void;
  removeNotification: (id: string) => void;
  markAsRead: (id: string) => void;
  markAllAsRead: () => void;
  clearSoft: () => void;
  clear: () => void;
  setShowAllNotifications: (hideReadNotifications: boolean) => void;
}

const useNotificationStore = create<NotificationStore & NotificationActions>()(
  devtools(
    persist(
      (set) => ({
        notifications: [],
        showAllNotifications: false,
        addNotification: (
          notification: Omit<Notification, "isRead" | "id" | "deleted" | "date">
        ): void =>
          set((state) => ({
            notifications: [
              ...state.notifications,
              {
                ...notification,
                isRead: false,
                id: nanoid(),
                deleted: false,
                date: dayjs(),
              },
            ],
          })),
        removeNotification: (id: string): void =>
          set((state) => ({
            notifications: state.notifications.filter(
              (notification) => notification.id !== id
            ),
          })),
        markAsRead: (id: string): void =>
          set((state) => ({
            notifications: state.notifications.map((notification) =>
              notification.id === id
                ? { ...notification, isRead: true }
                : notification
            ),
          })),
        markAllAsRead: (): void =>
          set((state) => ({
            notifications: state.notifications.map((notification) => ({
              ...notification,
              isRead: true,
            })),
          })),
        clearSoft: () => {
          set((state) => ({
            notifications: state.notifications.map((notification) => ({
              ...notification,
              deleted: true,
              isRead: true,
            })),
          }));
        },
        clear: () => {
          set(() => ({
            notifications: [],
          }));
        },
        setShowAllNotifications: (showAllNotifications: boolean): void =>
          set({ showAllNotifications }),
      }),
      {
        name: "notification-store",
        storage: createJSONStorage(() => storage, { reviver: dateDeserialize }),
        migrate: (
          persistedState: unknown
        ): NotificationStore & NotificationActions => {
          return persistedState as NotificationStore & NotificationActions;
        },
        merge: (
          persistedState: unknown,
          currentState: NotificationStore & NotificationActions
        ): NotificationStore & NotificationActions => {
          if (!persistedState) {
            return currentState;
          }

          const state = persistedState as NotificationStore;
          const ids = currentState.notifications.map((item) => item.id);

          return Object.assign(currentState, {
            notifications: currentState.notifications.concat(
              state.notifications.filter((item) => !ids.includes(item.id))
            ),
          });
        },
      }
    ),
    { name: "notification-store" }
  )
);

export default useNotificationStore;
