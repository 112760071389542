import { LeftOutlined } from "@ant-design/icons";
import { Button, Flex, Switch, Tabs, TabsProps, Typography } from "antd";
import dayjs, { Dayjs } from "dayjs";
import React, { CSSProperties } from "react";
import { useNavigate } from "react-router-dom";
import SwipeToRevealActions from "react-swipe-to-reveal-actions";
import styled from "styled-components";
import Empty from "../../core/components/empty/empty";
import HabitCard from "../../core/components/habit-card/habit-card";
import { ContentStyled, Layout } from "../../core/components/layout/layout";
import { getUnitsFormatted } from "../../core/formatters";
import mixpanelService from "../../services/mixpanel-service";
import useHabitStore from "../../store/habit-store";
import useSettingsStore from "../../store/settings-store";
import { Habit } from "../../types/habit";

const { Title, Text } = Typography;

const ButtonTextStyled = styled.div`
  background: var(--ant-color-error);
  color: var(--ant-color-bg-base);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ItemList: React.FC = () => {
  const history = useHabitStore((store) => store.history);
  const showCompleted = useSettingsStore((store) => store.showCompleted);
  const getCompletedAt = ({ id, repeat, endDate }: Habit): Dayjs | null => {
    const itemHistory = history[id] ?? [];

    if (!repeat) {
      const itemHistoryRecord = itemHistory.find((record) => record.done);

      return itemHistoryRecord?.done ? itemHistoryRecord.date : null;
    }

    if (!endDate) {
      return null;
    }

    return endDate.isBefore(dayjs().add(1, "day"), "day") ? null : endDate;
  };

  const habitList = useHabitStore((store) =>
    store.allItems
      .filter((item) => !!item.repeat)
      .filter((item) => showCompleted || !getCompletedAt(item))
  );

  const taskList = useHabitStore((store) =>
    store.allItems
      .filter((item) => !item.repeat)
      .filter((item) => showCompleted || !getCompletedAt(item))
  );

  const { setShowCompleted } = useSettingsStore();
  const { deleteItem } = useHabitStore();

  const navigate = useNavigate();

  const containerStyle: CSSProperties = {
    color: "var(--ant-color-text)",
    fontSize: "var(--ant-font-size)",
    lineHeight: "var(--ant-line-height)",
    listStyle: "none",
    fontFamily: "var(--ant-font-family)",
    position: "relative",
    background: "var(--ant-color-bg-container)",
    borderRadius: "var(--ant-border-radius-lg)",
    boxShadow: "var(--ant-box-shadow-tertiary)",
  };

  const items: TabsProps["items"] = [
    {
      key: "habits",
      label: "Habits",
      children: (
        <Flex gap="middle" vertical flex={1}>
          {habitList.length ? (
            habitList.map((habit) => (
              <SwipeToRevealActions
                key={habit.id}
                actionButtons={[
                  {
                    content: <ButtonTextStyled>Delete</ButtonTextStyled>,
                    onClick: () => {
                      deleteItem(habit.id);
                      mixpanelService.trackViewItemsDeleteItem();
                    },
                  },
                ]}
                actionButtonMinWidth={70}
                hideDotsButton={true}
                height="91px"
                containerStyle={containerStyle}
              >
                <HabitCard
                  key={habit.id}
                  icon={habit.icon}
                  title={habit.title}
                  createdAt={habit.createdAt}
                  completedAt={getCompletedAt(habit)}
                  subtitle={
                    habit.description ||
                    `Goal: ${getUnitsFormatted(
                      habit.goal?.unit.count ?? 1,
                      habit.goal?.unit.type ?? "times"
                    )} a day`
                  }
                  navigate={() => {
                    navigate(`/items/${habit.id}`);
                    mixpanelService.trackViewItemsSelectItem();
                  }}
                />
              </SwipeToRevealActions>
            ))
          ) : (
            <Empty text="You don't have any habits yet." showAnimation={true} />
          )}
        </Flex>
      ),
    },
    {
      key: "tasks",
      label: "Tasks",
      children: (
        <Flex gap="middle" vertical flex={1}>
          {taskList.length ? (
            taskList.map((habit) => (
              <SwipeToRevealActions
                key={habit.id}
                actionButtons={[
                  {
                    content: <ButtonTextStyled>Delete</ButtonTextStyled>,
                    onClick: () => {
                      deleteItem(habit.id);
                      mixpanelService.trackViewItemsDeleteItem();
                    },
                  },
                ]}
                actionButtonMinWidth={70}
                hideDotsButton={true}
                height="91px"
                containerStyle={containerStyle}
              >
                <HabitCard
                  key={habit.id}
                  icon={habit.icon}
                  title={habit.title}
                  createdAt={habit.createdAt}
                  completedAt={getCompletedAt(habit)}
                  subtitle={
                    habit.description ||
                    `Goal: ${getUnitsFormatted(
                      habit.goal?.unit.count ?? 1,
                      habit.goal?.unit.type ?? "times"
                    )} a day`
                  }
                  navigate={() => {
                    navigate(`/items/${habit.id}`);
                    mixpanelService.trackViewItemsSelectItem();
                  }}
                />
              </SwipeToRevealActions>
            ))
          ) : (
            <Empty text="You don't have any tasks yet." showAnimation={true} />
          )}
        </Flex>
      ),
    },
  ];

  return (
    <Layout
      header={
        <>
          <Button
            icon={<LeftOutlined />}
            type="text"
            shape="circle"
            size="large"
            onClick={() => navigate("/")}
          />
          <Title
            level={3}
            style={{
              flex: 1,
              margin: 0,
              textAlign: "center",
              marginLeft: "-40px",
            }}
          >
            Your Items
          </Title>
        </>
      }
      content={
        <ContentStyled>
          <Flex
            justify="space-between"
            align="center"
            style={{ height: "34px" }}
          >
            <Text style={{ margin: 0 }}>Show completed</Text>
            <Switch
              value={showCompleted}
              onChange={(event) => {
                setShowCompleted(event);
                mixpanelService.trackViewItemsShowCompleted(event);
              }}
            />
          </Flex>
          <Tabs
            defaultActiveKey="1"
            items={items}
            onChange={(activeKey: string) => {
              mixpanelService.trackViewItemsTab(activeKey);
            }}
          ></Tabs>
        </ContentStyled>
      }
    ></Layout>
  );
};

export default ItemList;
