import data, { Emoji } from "@emoji-mart/data";
import {
  Button,
  Calendar,
  Collapse,
  CollapseProps,
  Flex,
  Input,
  InputRef,
  Segmented,
  Select,
  Space,
  TimePicker,
  Typography,
} from "antd";
import dayjs, { Dayjs } from "dayjs";
import { SearchIndex, init } from "emoji-mart";
import { SegmentedValue } from "rc-segmented";
import React, { useEffect, useRef, useState } from "react";
import Day from "../../../pages/edit-item/day";
import Month from "../../../pages/edit-item/month";
import Week from "../../../pages/edit-item/week";
import useSettingsStore from "../../../store/settings-store";
import { Days } from "../../../types/days";
import { RepeatType } from "../../../types/repeat-type";
import { UnitType } from "../../../types/unit";
import * as formatters from "../../formatters";
import sortWeekdays from "../../utils/sort-weekdays";

init({ data });

const { TextArea } = Input;
const { Text } = Typography;

interface ItemFormProps {
  name: string | null;
  description: string;
  touched: boolean;
  period: RepeatType;
  days: Days[];
  week: number | null;
  month: number[];
  count: number;
  unit: UnitType;
  endDate: Dayjs | null;
  goal: "units" | "time";
  icon: string;
  setName: (name: string) => void;
  setDescription: (description: string) => void;
  setTouched: (touched: boolean) => void;
  setPeriod: (period: RepeatType) => void;
  setDays: (days: Days[]) => void;
  setWeek: (week: number | null) => void;
  setMonth: (month: number[]) => void;
  setCount: (count: number) => void;
  setUnit: (unit: UnitType) => void;
  setEndDate: (endDate: Dayjs | null) => void;
  setGoal: (goal: "units" | "time") => void;
  setIcon: (icon: string) => void;
}

const ItemForm: React.FC<ItemFormProps> = ({
  name,
  description,
  touched,
  period,
  days,
  week,
  month,
  count,
  unit,
  endDate,
  goal,
  icon,
  setName,
  setDescription,
  setTouched,
  setPeriod,
  setDays,
  setWeek,
  setMonth,
  setCount,
  setUnit,
  setEndDate,
  setGoal,
  setIcon,
}) => {
  const inputRef = useRef<InputRef>(null);
  const weekStartDay = useSettingsStore((state) => state.weekStartDay);
  const [emoji, setEmoji] = useState<string[]>([]);

  useEffect(() => {
    inputRef?.current?.focus();
  }, []);

  useEffect(() => {
    if (name !== null) {
      setTouched(true);
    }
  }, [name, setTouched]);

  useEffect(() => {
    SearchIndex.search(name).then((results) => {
      setEmoji((results ?? []).map((item: Emoji) => item.skins[0].native));
    });
  }, [name]);

  const periodOptions = [
    { label: "Daily", value: "daily" },
    { label: "Weekly", value: "weekly" },
    { label: "Monthly", value: "monthly" },
  ];

  const goalOptions = [
    { label: "Units", value: "units" },
    { label: "Timer", value: "timer" },
  ];

  const onPeriodChange = (value: SegmentedValue) => {
    setPeriod(value.toString() as RepeatType);
  };

  const onGoalChange = (value: SegmentedValue) => {
    setGoal(value.toString() as "units" | "time");

    if (value.toString() === "timer") {
      setUnit("timer");
    } else {
      setCount(1); // default
      setUnit("times");
    }
  };

  const getPeriodComponent = () => {
    switch (period) {
      case "weekly":
        return <Week selected={week} onChange={setWeek} />;

      case "monthly":
        return <Month selected={month} onChange={setMonth} />;

      case "daily":
      default:
        return (
          <Day selected={days} onChange={setDays} startFrom={weekStartDay} />
        );
    }
  };

  const getPeriodFormatted = () => {
    let moment: Days[] | number[] | number | null = null;

    switch (period) {
      case "daily":
        const weekDaysOrdered = sortWeekdays(weekStartDay)
          .map((day) => day.key)
          .filter((day) => days.includes(day));

        moment = weekDaysOrdered;
        break;

      case "weekly":
        moment = week as number | null;
        break;

      case "monthly":
        moment = month as number[];
        break;
    }

    return formatters.getPeriodFormatted(period, moment);
  };

  const getUnitsFormatted = (): string => {
    return formatters.getUnitsFormatted(count, unit);
  };

  const timesOptions = [
    { label: "1", value: 1 },
    { label: "2", value: 2 },
    { label: "3", value: 3 },
    { label: "4", value: 4 },
    { label: "5", value: 5 },
    { label: "6", value: 6 },
    { label: "7", value: 7 },
    { label: "8", value: 8 },
    { label: "9", value: 9 },
    { label: "10", value: 10 },
    { label: "15", value: 15 },
    { label: "20", value: 20 },
    { label: "30", value: 30 },
    { label: "40", value: 40 },
    { label: "50", value: 50 },
    { label: "100", value: 100 },
  ];

  const unitsOptions = [
    { label: "pages", value: "pages" },
    { label: "glasses", value: "glasses" },
    { label: "times", value: "times" },
    { label: "kilometers", value: "kilometers" },
  ];

  const items: CollapseProps["items"] = [
    {
      key: "period",
      label: <Text>{getPeriodFormatted()}</Text>,
      children: (
        <Flex vertical gap="middle" align="center">
          <Segmented
            size="large"
            options={periodOptions}
            onChange={onPeriodChange}
            value={period}
          />

          {getPeriodComponent()}
        </Flex>
      ),
    },
    {
      key: "goal",
      label: <Text>{getUnitsFormatted()}</Text>,
      children: (
        <Flex vertical gap="middle" align="center">
          <Segmented
            size="large"
            options={goalOptions}
            onChange={onGoalChange}
            value={goal}
          />

          {goal === "units" ? (
            <Flex vertical gap="small" style={{ width: "100%" }}>
              <Select
                defaultValue={count}
                style={{ flex: 1 }}
                options={timesOptions}
                onChange={setCount}
              />
              <Select
                defaultValue={unit}
                style={{ flex: 1 }}
                options={unitsOptions}
                onChange={setUnit}
              />

              <Text style={{ width: "100%", textAlign: "center" }}>
                peer day
              </Text>
            </Flex>
          ) : (
            <TimePicker
              value={dayjs().hour(0).minute(0).second(count)}
              onChange={(value) => {
                setUnit("timer");

                value &&
                  setCount(
                    dayjs
                      .duration({
                        hours: value?.hour(),
                        minutes: value?.minute(),
                        seconds: value?.second(),
                      })
                      .asSeconds() ?? 0
                  );
              }}
            />
          )}
        </Flex>
      ),
    },
    {
      key: "endDate",
      label: (
        <Text>{endDate ? endDate.format("DD MMM YYYY") : "Set end date"}</Text>
      ),
      children: (
        <Flex vertical gap="middle" align="center">
          <Calendar
            fullscreen={false}
            onSelect={(date) => setEndDate(date)}
            disabledDate={(date: Dayjs) => date.isBefore(dayjs(), "day")}
          />

          <Button
            type="default"
            onClick={() => setEndDate(null)}
            style={{ width: "100%" }}
          >
            Clear
          </Button>
        </Flex>
      ),
    },
  ];

  return (
    <Flex vertical gap="middle">
      <Flex gap="middle" align="center">
        {React.cloneElement(<span>{icon}</span>, {
          style: {
            fontSize: "24px",
            width: "35px",
            alignItems: "center",
            lineHeight: "30px",
            overflow: "hidden",
          },
        })}

        <Input
          placeholder="Read a book"
          allowClear
          ref={inputRef}
          onChange={(e) => setName(e.target.value)}
          status={touched && !name ? "error" : ""}
          count={{ show: true, max: 25 }}
          maxLength={25}
          value={name ?? ""}
        />
      </Flex>

      <Space
        direction="horizontal"
        size="small"
        style={{
          display: "flex",
          overflowX: "auto",
          whiteSpace: "nowrap",
          width: "100%",
          height: 32,
        }}
      >
        {emoji.length ? (
          emoji.map((item) => (
            <Button
              key={item}
              type="text"
              size="middle"
              icon={React.cloneElement(<span>{item}</span>, {
                style: {
                  alignItems: "center",
                  overflow: "hidden",
                },
              })}
              onClick={() => setIcon(item)}
              style={{ width: "100%" }}
            />
          ))
        ) : !!name?.length ? (
          <Flex
            gap="middle"
            align="center"
            justify="space-between"
            style={{ height: 32 }}
          >
            <Text type="secondary">
              There is no emoji to match it's name 😔
            </Text>
          </Flex>
        ) : (
          <Flex
            gap="middle"
            align="center"
            justify="space-between"
            style={{ height: 32 }}
          >
            <Text type="secondary" ellipsis>
              Start typing and emoji to choose will appear here 🚀
            </Text>
          </Flex>
        )}
      </Space>

      <TextArea
        allowClear
        rows={6}
        placeholder="Description"
        maxLength={500}
        value={description}
        count={{ show: true, max: 500 }}
        onChange={(e) => setDescription(e.target.value)}
        style={{ resize: "none", marginBottom: "16px" }}
      />

      <Collapse items={items} defaultActiveKey="period" />
    </Flex>
  );
};

export default ItemForm;
