import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { RepeatType } from "../types/repeat-type";
import { Days } from "../types/days";
import dayjs, { Dayjs } from "dayjs";
import { UnitType } from "../types/unit";

interface ItemState {
  touched: boolean;
  pictureSelectOpen: boolean;
  id: string | null;
  name: string | null;
  description: string;
  period: RepeatType;
  days: Days[];
  week: number | null;
  month: number[];
  count: number;
  unit: UnitType;
  endDate: Dayjs | null;
  goal: "units" | "time";
  icon: string;
}

interface ItemActions {
  setId: (id: string) => void;
  setName: (name: string) => void;
  setDescription: (description: string) => void;
  setTouched: (touched: boolean) => void;
  setPeriod: (period: RepeatType) => void;
  setDays: (days: Days[]) => void;
  setWeek: (week: number | null) => void;
  setMonth: (month: number[]) => void;
  setCount: (count: number) => void;
  setUnit: (unit: UnitType) => void;
  setEndDate: (endDate: Dayjs | null) => void;
  setGoal: (goal: "units" | "time") => void;
  setPictureSelectOpen: (pictureSelectionOpen: boolean) => void;
  setIcon: (icon: string) => void;
  clear: () => void;
}

const defaultItem: Readonly<ItemState> = {
  id: null,
  name: null,
  description: "",
  touched: false,
  period: "daily",
  days: [dayjs().format("dddd").toLowerCase() as Days],
  week: null,
  month: [],
  count: 1,
  unit: "times",
  endDate: null,
  goal: "units",
  pictureSelectOpen: false,
  icon: "😊",
};

const useItemStore = create<ItemState & ItemActions>()(
  devtools(
    (set): ItemState & ItemActions => {
      return {
        ...defaultItem,
        setId: (id: string) => set({ id }),
        setName: (name: string) => set({ name }),
        setDescription: (description: string) => set({ description }),
        setTouched: (touched: boolean) => set({ touched }),
        setPeriod: (period: RepeatType) => set({ period }),
        setDays: (days: Days[]) => set({ days }),
        setWeek: (week: number | null) => set({ week }),
        setMonth: (month: number[]) => set({ month }),
        setCount: (count: number) => set({ count }),
        setUnit: (unit: UnitType) => set({ unit }),
        setEndDate: (endDate: Dayjs | null) => set({ endDate }),
        setGoal: (goal: "units" | "time") => set({ goal }),
        setPictureSelectOpen: (pictureSelectOpen: boolean) =>
          set({ pictureSelectOpen }),
        setIcon: (icon: string) => set({ icon }),
        clear: () => set(defaultItem),
      };
    },
    { name: "item-store" }
  )
);

export default useItemStore;
