import { AppLauncher } from "@capacitor/app-launcher";
import { Capacitor } from "@capacitor/core";
import { Directory, Encoding, Filesystem } from "@capacitor/filesystem";
import dayjs from "dayjs";

interface Settings {
  exportAllData(text: string): Promise<void>;
  sendFeedback(): void;
}

class SettingsServiceWeb implements Settings {
  protected readonly feedbackUrl = `mailto:${process.env.REACT_APP_SUPPORT_EMAIL}?subject=${process.env.REACT_APP_NAME}%20feedback`;

  protected get fileName(): string {
    return `${process.env.REACT_APP_NAME}_data_${dayjs().format(
      "DD_MMM_YYYY"
    )}.txt`.replace(/\s/g, "_");
  }

  exportAllData = async (text: string): Promise<void> => {
    const data = new Blob([text], { type: "text/plain" });
    const url = URL.createObjectURL(data);
    const link = document.createElement("a");
    link.href = url;
    link.download = this.fileName;
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(url);
    document.body.removeChild(link);
  };

  sendFeedback = (): void => {
    window.open(this.feedbackUrl, "_blank");
  };

  openLink = (url: string): void => {
    window.open(url, "_blank");
  };
}

class SettingServiceNative extends SettingsServiceWeb implements Settings {
  exportAllData = async (text: string): Promise<void> => {
    try {
      const result = await Filesystem.writeFile({
        path: this.fileName,
        data: text,
        directory: Directory.Documents,
        encoding: Encoding.UTF8,
      });

      const canLaunch = await AppLauncher.canOpenUrl({ url: result.uri });

      if (canLaunch.value) {
        await AppLauncher.openUrl({ url: result.uri });
      } else {
        console.error("AppLauncher cannot open the URL.");
      }
    } catch (error) {
      console.error("Error exporting data:", error);
    }
  };

  sendFeedback = (): void => {
    AppLauncher.openUrl({
      url: this.feedbackUrl,
    });
  };

  openLink = (url: string): void => {
    AppLauncher.openUrl({
      url,
    });
  };
}

export default Capacitor.isNativePlatform()
  ? new SettingServiceNative()
  : new SettingsServiceWeb();
