import { Flex, Typography } from "antd";
import React from "react";
import Lottie, { Options } from "react-lottie";
import styled from "styled-components";
import animationData from "../../assets/lottie/yoga.json";
import { animationOptions } from "../../consts/animation-options";

const { Text } = Typography;

const FixedContainer = styled.div`
  height: 200px;
  min-height: 200px;
`;

interface EmptyProps {
  text?: string;
  showAnimation?: boolean;
}

const defaultOptions: Options = {
  ...animationOptions,
  loop: true,
  animationData,
};

const Empty: React.FC<EmptyProps> = ({ text, showAnimation }: EmptyProps) => {
  return (
    <Flex gap="middle" vertical align="center" style={{ margin: "100px 0" }}>
      <FixedContainer>
        {showAnimation && (
          <Lottie
            options={defaultOptions}
            height={200}
            isClickToPauseDisabled={false}
          />
        )}
      </FixedContainer>
      <Text>{text || "You don't have any habits yet."}</Text>
    </Flex>
  );
};

export default Empty;
